import React, { useRef } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Pagination, A11y } from "swiper"
import 'swiper/scss'
import 'swiper/scss/navigation'
import 'swiper/scss/pagination'
import CMSLink from "components/shared/cms-link"
import ArrowSvg from 'images/arrow.svg'

SwiperCore.use([Navigation])

const GalleryCarousel = ({data}) => {
  const { text, gallery, link } = data
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)
  return(
    <div className="gradient-1 overflow-x-hidden relative z-10">
      <div className="max-w-main w-90 mx-auto py-12 xl:py-24">
        {text &&
          <div
            className="[&>h3]:text-2xl [&>h3]:md:text-3xl mb-12  [&>p]:xl:text-[20px] [&>p]:text-[20px] [&>h3]:xl:text-[40px] "
            dangerouslySetInnerHTML={{ __html: text }}
          />
        }
        <div className="relative w-90 mx-auto md:w-full">
            <div ref={navigationPrevRef} className="absolute left-[-1.8rem] xl:left-[-2rem] top-[45%] z-50">
                <img className="scale-[-1]" src={ArrowSvg} alt="" />
            </div>
            <div ref={navigationNextRef} className="absolute right-[-1.8rem] xl:right-[-2rem] top-[45%] z-50">
                <img src={ArrowSvg} alt="" />
            </div>
          <Swiper
            modules={[Navigation,  A11y]}
            spaceBetween={20}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onInit={(swiper) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;
              swiper.navigation.init();
              swiper.navigation.update();
            }}
            className="galleryCarousel"
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              
              375: {
                slidesPerView: 2,
                
              },
              992: {
                slidesPerView: 4,
              }
            }}
            >
            {gallery?.acfGalleries?.images.map((image, i) => {
              return(
                <SwiperSlide key={`galleryCarousel${i}`}>
                  <div className="overflow-visible p-[3px] hover-blur relative rounded-[14px] gradient-4 lg:max-w-[315px]">
                    <div className="overflow-hidden rounded-[14px]">
                      <GatsbyImage
                      className="relative z-10 inner-rounded-important rounded-[10px] w-full h-full transition duration-300 ease-out hover:ease-in-out hover:scale-110"
                      image={image.localFile.resize.gatsbyImageData}
                      alt={image.altText}
                      />
                    </div>
                    <div className="blur-hover" />
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
      <div className="mt-14">
        {link &&
          <CMSLink url={link.url} target={link.target}>
            {link.title}
          </CMSLink>
        }
        </div>
      </div>
    </div>
  )
}

export default GalleryCarousel