import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import { filterCmsLinks } from "utils/helpers";

const SignatureSessions = ({ data }) => {
  const { day, text } = data;
  return (
    <div className="relative z-10 bg-dark-grey">
      <div className="max-w-main w-90 mx-auto py-12">
        {text && (
          <div
            className="text-[18px] font-bold [&>p]:pb-6 [&>p]:md:text-[22px] [&>p]:xl:text-[26px] md:mb-12"
            dangerouslySetInnerHTML={{ __html: filterCmsLinks(text) }}
          />
        )}
        <section className="flex flex-col gap-12">
          {day.map((day, i) => {
            return <SingleDay data={day} key={i} />;
          })}
        </section>
      </div>
    </div>
  );
};

export default SignatureSessions;

const SingleDay = ({ data }) => {
  const { title, time } = data;
  return (
    <div className="bg-gradient-to-r from-lightPink to-purple pt-4 px-4">
      {title && <h3 className="mt-4 text-[25px] md:text-[28px] lg:text-[35px] xl:text-[40px]">{title}</h3>}
      {time.map((time, i) => (
        <SingleTime data={time} key={i} />
      ))}
    </div>
  );
};
const SingleTime = ({ data }) => {
  const { text, images } = data;
  return (
    <div className="mb-12">
      {text && (
        <div
          className="mt-5 [&>p>strong]:font-black [&>p>strong]:font-display [&>p]:mt-2 [&>p>strong]:lg:text-[20px]  [&>p]:lg:text-[18px] [&>p>strong]:xl:text-[24px] [&>p]:xl:text-[20px]"
          dangerouslySetInnerHTML={{ __html: filterCmsLinks(text) }}
        />
      )}
      {images && (
        <div className="grid grid-cols-2 gap-x-3 gap-y-4 mt-7 md:grid-cols-4 xl:gap-x-10">
          {images.map((item, i) => {
            const { image } = item;
            return (
              <React.Fragment key={i}>
                {image && (
                  <GatsbyImage
                    key={i}
                    image={image.localFile.childImageSharp.gatsbyImageData}
                    alt={image.altText}
                    className="w-full h-full max-w-[228px] mx-auto"
                  />
                )}
              </React.Fragment>
            );
          })}
        </div>
      )}
    </div>
  );
};
