import React from "react"
import { graphql } from "gatsby"
import Layout from "components/layout"
import FlexibleContent from "components/flexible-content/flexible-content"

export default function PageTemplate({data}) {
  const { wpPage } = data
  return(
    <Layout data={wpPage}> 
      <FlexibleContent blocks={wpPage?.acfFlexibleContent?.blocks} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      uri
      date
      content
      seo {
        title
        metaDesc
        opengraphImage {
          sourceUrl
        }
        breadcrumbs {
          text
          url
        }
        canonical
      }
      # template {
      #   ... on WpDefaultTemplate {
          ...defaultTemplateQuery
        # }
      # }
    }
  }
`