import React, { useLayoutEffect, useRef, useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import CMSLink from "components/shared/cms-link";
import Modal from "components/shared/modal";

import { gsap } from "utils/gsap";

const TheExperience = ({ data }) => {
  const { title, text, cards } = data;
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  //* Loading animations
  const target = useRef();
  const tl = useRef();
  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      tl.current = gsap
        .timeline({
          duration: 0.4,
          scrollTrigger: {
            trigger: target.current,
            toggleActions: "play none none none",
            start: "top center",
          },
        })
        .to(".animate-from-left", {
          opacity: 1,
          x: "0",
          stagger: {
            each: 0.1,
          },
        })
        .to(".animate-from-left", {
          opacity: 1,
          x: "0",
          stagger: {
            // from: 'random',
            // axis: 'null',
            each: 0.15,
          },
        });
    }, target);
  }, []);
  return (
    <>
      <div className="bg-white py-12" ref={target}>
        <div className="max-w-main w-[85%] mx-auto text-black">
          {title && (
            <h2 className="animate-from-left text-[25px] m-0 p-0 md:text-[35px] xl:text-[50px]">
              {title}
            </h2>
          )}
          {text && (
            <div
              dangerouslySetInnerHTML={{ __html: text }}
              className={`animate-from-left mb-6 [&>h3]:text-blue [&>h3]:text-[18px] [&>h3]:pb-0 [&>h3]:md:text-[25px] [&>h3]:xl:text-[32px] [&>p]:mt-2 [&>p]:xl:text-[18px] [&>p]:md:max-w-[1000px]`}
            />
          )}
          <div className="flex flex-col gap-3 sm:grid sm:grid-cols-2 lg:grid-cols-4 lg:gap-2 md:mt-8 xl:mt-12  xl:gap-4">
            {cards.map((card, i) => (
              <SingleCard
                data={card}
                key={i}
                setOpen={setOpen}
                setModalData={setModalData}
                className="animate-from-left"
              />
            ))}
          </div>
        </div>
      </div>
      {open && (
        <Modal open={open} setOpen={setOpen} experience>
          <ExperienceModal data={modalData} />
        </Modal>
      )}
    </>
  );
};

export default TheExperience;

const SingleCard = ({ data, setModalData, setOpen, className }) => {
  const { title, link, select, desktopImage, mobileImage, popUp } = data;
  console.log(data)
  const handleClick = () => {
    setModalData(popUp);
    setOpen(true);
  };
  return (
    <>
      {select === "link" ? (
        <CMSLink url={link.url} target={link.target} type="notButton">
          <div
            className={`relative gradient-5 rounded-[12px]  mx-auto md:rounded-[16px] cursor-pointer experienceHover  ${className}`}
          >
            <div className="p-[2px] md:p-[6px]">
              <div className="w-full h-full sm:hidden text-center">
                {mobileImage?.localFile && (
                  <GatsbyImage
                    image={getImage(mobileImage.localFile)}
                    alt={title}
                    className="relative w-full h-full rounded-[10px] after:content:[''] after:absolute after:inset-0 after:w-full after:h-full after:bg-gradient-to-t after:from-black after:z-20"
                  />
                )}
              </div>
              <div className="hidden sm:block h-full w-full text-center">
              {desktopImage?.localFile && (
                <GatsbyImage
                  className="relative w-full h-full rounded-[10px] after:content:[''] after:absolute after:inset-0 after:w-full after:h-full after:bg-gradient-to-t after:from-black after:z-20"
                  image={getImage(desktopImage.localFile)}
                  alt={title}
                />
              )}
              </div>
            </div>
            <div className="absolute bottom-5 w-full text-center z-10 md:bottom-5 xl:px-2">
              {title && (
                <div
                  dangerouslySetInnerHTML={{ __html: title }}
                  className="text-[22.5px] text-white font-bold font-display uppercase leading-[28px] [&>h5]:xl:text-[28px]"
                />
              )}
              {select === "link" ? (
                <>
                  {link && (
                    <CMSLink
                      url={link.url}
                      target={link.target}
                      type="notButton"
                      className="text-white hover:text-mint hover:underline lg:mt-2 xl:block cursor-pointer"
                    >
                      {link.title}
                    </CMSLink>
                  )}
                </>
              ) : (
                <>
                  {popUp && (
                    <p
                      onClick={() => handleClick()}
                      className="text-white hover:text-mint hover:underline lg:mt-2 xl:block cursor-pointer	"
                    >
                      Learn More
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        </CMSLink>
      ) : (
        <div>
        <div
          className={`relative gradient-5 rounded-[12px]  mx-auto md:rounded-[16px] cursor-pointer experienceHover  ${className}`}
          onClick={() => handleClick()}
        >
          <div className="p-[2px] md:p-[6px]">
            <div className="w-full h-full sm:hidden">
              {mobileImage?.localFile && (
                <GatsbyImage
                  image={getImage(mobileImage.localFile)}
                  alt={title}
                  className="relative w-full h-full rounded-[10px] after:content:[''] after:absolute after:inset-0 after:w-full after:h-full after:bg-gradient-to-t after:from-black after:z-20"
                />
              )}
            </div>
            <div className="hidden sm:block h-full w-full">
              {desktopImage?.localFile && (
                <GatsbyImage
                  className="relative w-full h-full rounded-[10px] after:content:[''] after:absolute after:inset-0 after:w-full after:h-full after:bg-gradient-to-t after:from-black after:z-20"
                  image={getImage(desktopImage.localFile)}
                  alt={title}
                />
              )}
            </div>
          </div>
          <div className="absolute bottom-5 w-full text-center z-10 md:bottom-5 xl:px-2">
            {title && (
              <div
                dangerouslySetInnerHTML={{ __html: title }}
                className="text-[22.5px] text-white font-bold font-display uppercase leading-[28px] [&>h5]:xl:text-[28px]"
              />
            )}
            {popUp && (
              <p
                onClick={() => handleClick()}
                className="text-white hover:text-mint hover:underline lg:mt-2 xl:block cursor-pointer	"
              >
                Learn More
              </p>
            )}
          </div>
        </div>
        </div>
        
      )}
    </>
  );
};

const ExperienceModal = ({ data }) => {
  const { title, text, images } = data;
  return (
    <div className="w-90 h-full mx-auto pt-10 experienceGrid">
      <div className="h-full w-full">
        {title && (
          <div
            dangerouslySetInnerHTML={{ __html: title }}
            className="text-[20px] text-black font-display font-bold mb-2 md:text-[25px] xl:text-[45px]"
          />
        )}
        {text && (
          <div
            dangerouslySetInnerHTML={{ __html: text }}
            className="[&>h3]:text-2xl [&>p]:pb-3 [&>ul]:list-disc [&>ul]:pl-6  [&>ul]:pb-3  [&>ul>li>h4]:pb-0 [&>h4]:pt-4 [&>p]:text-[14px] [&>li]:text-[14px] [&>p]:md:text-[16px] [&>li]:md:text-[14px]  [&>h3]:md:text-3xl [&>ul]:xl:text-[17px]   [&>ul>li>h4]:xl:text-[30px] [&>h4]:xl:text-[30px] [&>p]:xl:text-[17px] [&>li]:xl:text-[16px]  [&>h3]:xl:text-[40px] "
            // className="mb-2 text-[14px] [&>p]:mb-2 md:text-[16px] xl:text-[18px]"
          />
        )}
      </div>
      <div className="block">
        {images.map((image, i) => {
          return (
            <div
              className={`h-full max-w-[250px] gradient-5 p-[5px] md:min-h-[250px] md:min-w-[250px] md:max-h-[250px] rounded-[15px] ${
                i === 1 && "hidden md:block md:mt-[1rem] xl:mb-[2rem]"
              }`}
            >
              {image.image?.localFile && (
                <GatsbyImage
                  className="experienceRelative rounded-[10px] md:min-h-[240px] md:min-w-[240px] md:max-w-[240px] md:max-h-[238px]"
                  image={getImage(image.image.localFile)}
                  alt={title}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
