import React, { useEffect, useRef, useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { gsap } from "utils/gsap";

const Gallery = ({ data }) => {
  const { gallery } = data;
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const images = gallery?.acfGalleries?.images;

  const galleryRef = useRef();
  const tl = useRef();
  useEffect(() => {
    const ctx = gsap.context(() => {
      tl.current = gsap.timeline({
        scrollTrigger: {
          trigger: galleryRef.current,
        }
      })
        .from('.gallery-item', {
        // .from(galleryRef.current, {
          opacity: 0,
          x: '-20px',
          stagger: 0.2,
        });
    }, galleryRef);
  }, []);

  return (
    <div className="relative z-10 bg-dark-grey">
      <div className="max-w-main w-90 mx-auto">
        <div ref={galleryRef}  className="grid py-8 grid-cols-2 gap-4 md:grid-cols-3 md:py-16 xl:py-20 desktop-grid	">
        {images && (
          <>
              {images.map((image, i) => {
            return (
              <button
                key={`galleryBtn${i}`}
                className="gallery-item relative hover-blur p-[2px] bg-purple rounded-[13px] gradient-4"
                onClick={() => {
                  setIsOpen(true);
                  setPhotoIndex(i);
                }}
              >
                <div className="rounded-[13px] overflow-hidden">
                  <GatsbyImage
                    className="relative z-10 inner-rounded-important rounded-[10px] w-full h-full transition duration-300 ease-out hover:ease-in hover:scale-110"
                    image={image.localFile.resize.gatsbyImageData}
                    alt={image.altText}
                  />
                </div>
                <div className="blur-hover z-20" />
              </button>
            );
          })}
          </>
        )}
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].localFile.publicURL}
            nextSrc={
              images[(photoIndex + 1) % images.length].localFile.publicURL
            }
            prevSrc={
              images[(photoIndex + images.length - 1) % images.length].localFile
                .publicURL
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
          />
        )}
      </div>
    </div>
  );
};
export default Gallery;