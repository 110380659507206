import React from "react"

const Experts = ({data}) => {
  const { text } = data

  return(
    "Experts listing TBC..."
  )
}

export default Experts