import React, { useEffect, useState } from "react"
import Amplify, { API } from "aws-amplify"
import awsconfig from "aws-exports"
import { validateRequired, validateEmail, validateFile } from "utils/validations"
import loadRecaptcha from "utils/loadRecaptcha"
import Button from "components/shared/button"

Amplify.configure(awsconfig)

const PressForm = ({data}) => {
  const [values, setValues] = useState({
    Name: '',
    fname: '',
    lastname: '',
    email: '',
    phone: '',
    company: '',
    companysocials: '',
    message: ''
  })
  const [required, setRequired] = useState({
    fname: '',
    lastname: '',
    email: '',
    company: '',
    message: ''
  })
  const [validate, setValidate] = useState({
    fname: '',
    lastname: '',
    email: '',
    phone: '',
    company: '',
    companysocials: '',
    message: ''
  })
  const [success, setSuccess] = useState(false)
  const [sendingFormRequest, setSendingFormRequest] = useState(false)

  const {
    sendTo,
    text,
    successText
  } = data

  useEffect(() => {
    loadRecaptcha()
  }, [values])

  const handleChange = (e, name=false, value=false) => {
    if (e !== false) {
      const { target } = e
      name = target.name
      value = target.value

      if (e.target.type === 'file') {
        value = {}
        value.value = e.target.files[0]
        value.name = e.target.files[0].name
        value.type = "file"
        value.size = e.target.files[0].size
      }
    }

    // console.log(value)

    // set required fields value
    if (required.hasOwnProperty(name)) {
      setRequired(required => ({
        ...required,
        [name]: value,
      }))
    }

    // Update value in state
    setValues(values => ({
      ...values,
      [name]: value,
    }))
  }

  const resetValues = () => {
    let newVals = {}
    for (let key in values) {
      newVals[key] =  ""
    }
    setValues({
      ...values,
      ...newVals
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setSendingFormRequest(true)

    let errors = false
    const validateObj = {}
    for (const key in validate) {
      if (required[key] === '' && key) {
        validateObj[key] = false
        errors = true
      } else {
        validateObj[key] = true
      }
      setValidate(validateObj)
    }

    // return if errors
    if (errors) {
      setSendingFormRequest(false)
      return
    }

    // create the data to send and add sendTo to data

    const formData = {}
    for (const key in values) {
      formData[key] = values[key]
    }
    formData["sendTo"] = sendTo

    const apiName = 'TMCPressFormAPI'
    const path = '/press'
    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: formData,
    }

    return API.get(apiName, path, myInit)
    .then(response => {
      // return response.data
      setSendingFormRequest(false)

      if (response.status === 200 && response.data.result === "success") {
        setSuccess(true)
        resetValues()
      }
    })
    .catch(error => {
      console.log(error)
    })
  }

  // console.log(values)
  // console.log(validate)
  // console.log(errors)
  // console.log(validate[`file1`])

  return(
    <div className="bg-gradient-to-b from-dark-blue to-darker-blue py-20" id="vendor-form">
      <div className="container max-w-main w-90">
        {text && (
          <div
            className="[&>h3]:text-2xl [&>h3]:md:text-3xl md:mb-12 [&>h3]:xl:text-5xl [&>p]:xl:text-[20px] [&>p]:text-[20px] [&>h3]:xxl:text-[40px] pb-4"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        )}
        <form 
          onSubmit={e => {
            e.preventDefault()
            if (!sendingFormRequest) {
              handleSubmit(e)
            }
          }}
          noValidate
          className="pb-8 max-w-[950px]"
        > 
          <input
            type="text"
            id="yourName"
            name="Name"
            placeholder="Your name here"
            className="hidden"
            autoComplete="none"
            tabIndex="-1"
            onChange={e => {
              handleChange(e)
            }}
          />
          <div className="md:flex md:flex-wrap md:-mx-4">
            <div className="md:basis-1/2 md:px-4 pb-4 md:pb-6">
              <label htmlFor="fname" className="pb-2 block">First name*</label>
              <input 
                type="text" 
                name="fname"
                id="fname"
                value={values.fname} 
                placeholder="Enter your first name"
                onChange={e => {
                  handleChange(e)
                  validateRequired(validate, e)
                }}
                className="text-black p-3 w-full placeholder:text-light-grey"
              />
              {(!validate.fname && validate.fname !== '') &&
                <p className="text-pink">Your first name is required</p>
              }
            </div>
            <div className="md:basis-1/2 md:px-4 pb-4 md:pb-6">
              <label htmlFor="lastname" className="pb-2 block">Last name*</label>
              <input 
                type="text" 
                name="lastname"
                id="lastname"
                value={values.lastname} 
                placeholder="Enter your last name"
                onChange={e => {
                  handleChange(e)
                  validateRequired(validate, e)
                }}
                className="text-black p-3 w-full placeholder:text-light-grey"
              />
              {(!validate.lastname && validate.lastname !== '') &&
                <p className="text-pink">Your last name is required</p>
              }
            </div>
            <div className="md:basis-1/2 md:px-4 pb-4 md:pb-6">
              <label htmlFor="phone" className="pb-2 block">Phone number</label>
              <input 
                type="text" 
                name="phone"
                id="phone"
                value={values.phone} 
                placeholder="Enter your phone number (+1)"
                onChange={e => {
                  handleChange(e)
                }}
                className="text-black p-3 w-full placeholder:text-light-grey"
              />
            </div>
            <div className="md:basis-1/2 md:px-4 pb-4 md:pb-6">
              <label htmlFor="email" className="pb-2 block">Email*</label>
              <input 
                type="email" 
                name="email"
                id="email"
                value={values.email} 
                placeholder="Enter your email"
                onChange={e => {
                  handleChange(e)
                  validateRequired(validate, e)
                  validateEmail(validate, e)
                }}
                className="text-black p-3 w-full placeholder:text-light-grey"
              />
              {(!validate.email && validate.email !== '') &&
                <p className="text-pink">Your email is required</p>
              }
            </div>
            <div className="md:basis-1/2 md:px-4 pb-4 md:pb-6">
              <label htmlFor="company" className="pb-2 block">Outlet/Company*</label>
              <input 
                type="text" 
                name="company"
                id="company"
                value={values.company} 
                placeholder="Enter you outlet / company"
                onChange={e => {
                  handleChange(e)
                  validateRequired(validate, e)
                }}
                className="text-black p-3 w-full placeholder:text-light-grey"
              />
              {(!validate.company && validate.company !== '') &&
                <p className="text-pink">Your outlet / company is required</p>
              }
            </div>
            <div className="md:basis-1/2 md:px-4 pb-4 md:pb-6">
              <label htmlFor="companysocials" className="pb-2 block">Outlet's social handles*</label>
              <input 
                type="text" 
                name="companysocials"
                id="companysocials"
                value={values.companysocials} 
                placeholder="Enter you outlet's social handles"
                onChange={e => {
                  handleChange(e)
                  validateRequired(validate, e)
                }}
                className="text-black p-3 w-full placeholder:text-light-grey"
              />
            </div>
          </div>
          <div className="md:-mx-4">
            <div className="md:px-4 pb-4 md:pb-6">
              <label htmlFor="message" className="pb-2 block">Brief description of coverage plans*</label>
              <textarea 
                name="message"
                id="message"
                placeholder="Enter your brief description of item or collection" 
                value={values.message} 
                rows="8"
                onChange={e => {
                  handleChange(e)
                  validateRequired(validate, e)
                }}
                className="text-black p-3 w-full placeholder:text-light-grey"
              />
              {(!validate.message && validate.message !== '') &&
                <p className="text-pink">This field is required</p>
              }
            </div>
          </div>
          <Button className="mb-2 mt-0" type="submit">
            Submit
          </Button>
        </form>
        {(success && successText) && (
          <div dangerouslySetInnerHTML={{ __html: successText }} className="text-white text-2xl" id="vendor-success" />
        )}
      </div>
    </div>
  )
}

export default PressForm