import React, { useRef } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import CMSLink from "components/shared/cms-link";
import ArrowSvg from "images/arrow.svg";

SwiperCore.use([Navigation]);

const ExpertsCarousel = ({ data }) => {
  const { text, experts, link } = data;
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  return (
    <div className="bg-dark-grey overflow-x-hidden relative z-10">
      <div className="max-w-main relative w-90 mx-auto py-12 xl:py-24">
        {text &&
          <div
            className="[&>h3]:text-2xl [&>h3]:md:text-3xl mb-12 [&>h3]:xl:text-5xl [&>p]:xl:text-[20px] [&>p]:text-[20px] [&>h3]:xxl:text-[40px]"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        }
        <div className="relative w-90 mx-auto md:w-full">
          <div ref={navigationPrevRef} className="absolute left-[-1.8rem] xl:left-[-2rem] top-[37%] md:top-[31%] z-50">
            <img className="scale-[-1]" src={ArrowSvg} alt="" />
          </div>
          <div ref={navigationNextRef} className="absolute right-[-1.8rem] xl:right-[-2rem] top-[37%] md:top-[31%] z-50">
            <img src={ArrowSvg} alt="" />
          </div>
        <Swiper
          modules={[Navigation, A11y]}
          spaceBetween={20}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
          className="expertsCarousel"
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            576: {
              slidesPerView: 2,
            },
            992: {
              slidesPerView: 4,
            },
          }}
        >
          {experts?.map((expert, i) => {
            const { title } = expert;
            const { image, company, expertIn } = expert.acfExperts.details;

            return (
              <SwiperSlide key={`expertCarousel${i}`}>
                <div>
                  <div className="overflow-visible hover-blur relative p-[3px] bg-purple rounded-[13px] gradient-4 mx-auto md:w-full lg:max-w-[315px]">
                    <GatsbyImage
                      className="relative z-10 inner-rounded-important rounded-[10px] w-full h-full transition duration-150 ease-out hover:ease-in"
                      image={image.localFile.childImageSharp.gatsbyImageData}
                      alt={image.altText}
                      />
                      <div className="blur-hover" />
                  </div>
                  <div className="my-4 mx-auto md:w-90">
                    {title && <div className="md:text-[20px]">Name: {title}</div>}
                    {company && <div className="md:text-[20px]">Company: {company}</div>}
                    {expertIn && <div className="md:text-[20px]">Expert in: {expertIn}</div>}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        </div>
        {link && (
          <CMSLink url={link.url} target={link.target}>
            {link.title}
          </CMSLink>
        )}
      </div>
    </div>
  );
};

export default ExpertsCarousel;