import React, { useEffect, useRef } from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import { filterCmsLinks } from "utils/helpers";
import { gsap } from 'utils/gsap'


const WhatsOn = ({ data }) => {
  const { title, mobileImage, desktopImage, block } = data;
  const whatsOnRef = useRef();
  const tl = useRef();
  useEffect(() => {
    const ctx = gsap.context(() => {
      tl.current = gsap.timeline({
        duration: .4,
        scrollTrigger: {
          trigger: whatsOnRef.current,
          toggleActions: "play none none none",
          start: "top 75%"
        }
      })
        .from('.title', {
          opacity: 0,
          x: '-100px',
        })
        .from('.item', {
          opacity: 0,
          x: '-100px',
          stagger: 0.25
        });
    }, whatsOnRef);
  }, []);
  return (
    <div className="max-w-xxl relative z-10 mx-auto bg-dark-grey after:content after:absolute after:inset-0 after:w-full after:h-full after:bg-dark-overlay after:z-[10]">
      <div  ref={whatsOnRef} className="max-w-main w-90 py-12 mx-auto relative z-[11] md:py-14 xl:py-20">
        {title && (
          <h3 className="title text-[25px] md:text-3xl xl:text-[40px] text-center">{title}</h3>
        )}
        {block && (
          <div className="py-4 flex flex-col gap-8 sm:grid sm:gap-0 sm:grid-cols-2 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-4 xl:gap-x-2">
            {block.map((item, i) => {
              const { day, text } = item;
              return (
                <div key={i} className="item xl:pl-12 xl:mt-10">
                  {day && (
                    <h4 className=" text-mint pb-0 text-[19px] xl:text-[20px]">
                      {day}
                    </h4>
                  )}
                  {text && (
                    <div
                      className="[&>ul]:pl-6 [&>ul]:list-disc [&>ul]:mt-3 [&>ul>li]:text-[16px]"
                      dangerouslySetInnerHTML={{ __html: filterCmsLinks(text) }}
                    />
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
      {desktopImage && (
        <div className="hidden md:block h-full w-full inset-0 top-0 left-0 bottom-0 right-0 object-cover absolute pointer-events-none z-10">
          <GatsbyImage
            image={desktopImage.localFile.childImageSharp.gatsbyImageData}
            alt={desktopImage.altText}
            className="w-full h-full"
          />
        </div>
      )}
      {mobileImage && (
        <div className="importantAbsolute md:hidden h-full w-full inset-0 top-0 left-0 bottom-0 right-0 object-cover absolute pointer-events-none z-10">
          <GatsbyImage
            image={mobileImage.localFile.childImageSharp.gatsbyImageData}
            alt={desktopImage.altText}
            className="w-full h-full"
          />
        </div>
      )}
    </div>
  );
};

export default WhatsOn;