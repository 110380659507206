import React, { useEffect, useState } from "react"
import { window } from 'browser-monads';

import NewHero from "./blocks/new-hero"
import InternalHero from "./blocks/internal-hero"
import TextFullWidth from "./blocks/text-full-width"
import TickerText from "./blocks/ticker-text"
import TextWithGraphics from "./blocks/text-with-graphics"
import ImageWithWhiteTextBox from "./blocks/image-with-white-text-box"
import ImageWithText from "./blocks/image-with-text"
import GalleryCarousel from "./blocks/gallery-carousel"
import Gallery from "./blocks/gallery"
import ExpertsCarousel from "./blocks/experts-carousel"
import Experts from "./blocks/experts"
import Sponsors from "./blocks/sponsors"
import ContactForm from "./blocks/contact-form/contact-form"
import SingleLineText from "./blocks/single-line-text";
import Vendors from "./blocks/vendors";
import TestVideo from "./blocks/test-video";
import TwoColumnVenues from "./blocks/two-column-venues";
import SingleVenue from "./blocks/single-venue";
import Speakers from "./blocks/speakers";
import VideoWithText from './blocks/video-with-text'
import FullWidthImage from './blocks/full-width-image'
import Faqs from './blocks/faqs'
import HeroWithLogos from './blocks/hero-with-logos'
import AuctionListing from './blocks/auction-listing'
import SignatureSessions from './blocks/signature-sessions'
import FullWidthVideo from './blocks/full-width-video'
import FullWidthCarousel from './blocks/full-width-carousel'
import GridCarousel from './blocks/grid-carousel'
import WhatsOn from './blocks/whats-on'
import TwoColumnText from './blocks/two-column-text'
import Tickets from './blocks/tickets'
import TheExperience from './blocks/the-experience'
// import Schedule from './blocks/schedule'
import AuctionForm from './blocks/auction-form'
import PressForm from './blocks/press-form'
import NewsListing from './blocks/news-listing'

export default function FlexibleContent({blocks}) {
  const [index, setIndex] = useState(false)
  useEffect(() => {
    setTimeout(() => {   
      if (window.location.pathname =="/") {
        setIndex(true)
      }else {
        setIndex(false)
      }
      if (
        blocks[0].__typename !== "WpPage_Acfflexiblecontent_Blocks_Hero" &&
        blocks[0].__typename !== "WpPage_Acfflexiblecontent_Blocks_InternalHero" &&
        blocks[0].__typename !== "WpPage_Acfflexiblecontent_Blocks_InternalHeroWithLogos" 
      ) {
        const header = document.getElementById("main-header");
        if (header !== null) {
          let headerHeight = header.clientHeight
          document.body.style.paddingTop = `${headerHeight}px`
        }
      } else {
        document.body.style.paddingTop = `0`
      }
    }, 200);
  }, [])

  if (blocks) {
    return blocks.map((block, i) => {
      console.log(block.__typename)
      switch (block.__typename) {
      
        /* -- ACF BLOCKS -- */
        case 'WpPage_Acfflexiblecontent_Blocks_Hero':
          // return <NewHero data={block} key={i} />
          return <NewHero data={block} key={i} />

        case 'WpPage_Acfflexiblecontent_Blocks_InternalHero':
          return <InternalHero data={block} key={i} />
          
        case 'WpPage_Acfflexiblecontent_Blocks_TextFullWidth':
          return <TextFullWidth data={block} key={i} />

        case 'WpPage_Acfflexiblecontent_Blocks_TickerText':
          return <TickerText data={block} key={i} />
          
        case 'WpPage_Acfflexiblecontent_Blocks_TextWithGraphics':
          return <TextWithGraphics data={block} key={i} isHomePage={index} />

        case 'WpPage_Acfflexiblecontent_Blocks_ImageWithWhiteTextBox':
          return <ImageWithWhiteTextBox data={block} key={i} />

        case 'WpPage_Acfflexiblecontent_Blocks_ImageWithText':
          return <ImageWithText data={block} key={i} />

        case 'WpPage_Acfflexiblecontent_Blocks_GalleryCarousel':
          return <GalleryCarousel data={block} key={i} />

        case 'WpPage_Acfflexiblecontent_Blocks_GalleryFullListing':
          return <Gallery data={block} key={i} />

        case 'WpPage_Acfflexiblecontent_Blocks_ExpertsCarousel':
          return <ExpertsCarousel data={block} key={i} />

        case 'WpPage_Acfflexiblecontent_Blocks_ExpertsFullListing':
          return <Experts data={block} key={i} />

        case 'WpPage_Acfflexiblecontent_Blocks_Sponsors':
          return <Sponsors data={block} key={i} />

        case 'WpPage_Acfflexiblecontent_Blocks_ContactForm':
          return <ContactForm data={block} key={i} />

        case 'WpPage_Acfflexiblecontent_Blocks_SingleLineText':
          return <SingleLineText data={block} key={i} />

        case 'WpPage_Acfflexiblecontent_Blocks_Vendors':
          return <Vendors data={block} key={i} />
          
        //TODO remove when client has viewed
        case 'WpPage_Acfflexiblecontent_Blocks_Testvideo':
          return <TestVideo data={block} key={i} />

        case 'WpPage_Acfflexiblecontent_Blocks_TwoColumnVenues':
          return <TwoColumnVenues data={block} key={i} />
          
        case 'WpPage_Acfflexiblecontent_Blocks_SingleVenue':
          return <SingleVenue data={block} key={i} />

        case 'WpPage_Acfflexiblecontent_Blocks_Speakers':
          return <Speakers data={block} key={i} />
          
        case 'WpPage_Acfflexiblecontent_Blocks_VideoWithText':
          return <VideoWithText data={block} key={i} />

        case 'WpPage_Acfflexiblecontent_Blocks_FullWidthImage':
          return <FullWidthImage data={block} key={i} />

        case 'WpPage_Acfflexiblecontent_Blocks_Faqs':
          return <Faqs data={block} key={i} />

        case 'WpPage_Acfflexiblecontent_Blocks_InternalHeroWithLogos':
          return <HeroWithLogos data={block} key={i} />

        case 'WpPage_Acfflexiblecontent_Blocks_AuctionListings':
          return <AuctionListing data={block} key={i} />

        case 'WpPage_Acfflexiblecontent_Blocks_SignatureSessions':
          return <SignatureSessions data={block} key={i} />

        case 'WpPage_Acfflexiblecontent_Blocks_FullWidthVideo':
          return <FullWidthVideo data={block} key={i} />

        case 'WpPage_Acfflexiblecontent_Blocks_FullWidthCarousel':
          return <FullWidthCarousel data={block} key={i} />

        case 'WpPage_Acfflexiblecontent_Blocks_GridCarousel':
          return <GridCarousel data={block} key={i} />

        case 'WpPage_Acfflexiblecontent_Blocks_WhatsOn':
          return <WhatsOn data={block} key={i} />

        case 'WpPage_Acfflexiblecontent_Blocks_TwoColumnText':
          return <TwoColumnText data={block} key={i} />

        case 'WpPage_Acfflexiblecontent_Blocks_TicketBlock':
          return <Tickets data={block} key={i} />

        // case 'WpPage_Acfflexiblecontent_Blocks_Schedule':
        //   return <Schedule data={block} key={i} />

        case 'WpPage_Acfflexiblecontent_Blocks_AuctionForm':
          return <AuctionForm data={block} key={i} />

        case 'WpPage_Acfflexiblecontent_Blocks_PressInquiriesForm':
          return <PressForm data={block} key={i} />

        case 'WpPage_Acfflexiblecontent_Blocks_TheExperience':
          return <TheExperience data={block} key={i} />

        case 'WpPage_Acfflexiblecontent_Blocks_NewsListing':
          return <NewsListing data={block} key={i} />
          
        default:
         return <pre key={i}>{JSON.stringify(block, null, 2)}</pre>
      }
    })
  } else return null
}