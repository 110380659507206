import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import CMSLink from "./cms-link";

const SingleAuction = ({ data, index }) => {
  const { title, acfAuctions } = data;
  const { estimatedValue, startingBid, link, image } = acfAuctions.details;
  return (
    <div className="relative z-10 bg-white rounded-[25px] p-6 max-w-[400px] mx-auto lg:max-w-[576px] xl:p-11">
      {image && (
        <GatsbyImage
          image={image.localFile.childImageSharp.gatsbyImageData}
          alt={image.altText}
          className="rounded-[17px]"
        />
      )}
      <div className="text-black mt-4">
        <h3 className="text-[19px] mt-6 lg:text-[38px]">{index}</h3>
        {title && <h4 className="text-[19px] lg:text-[22px] xl:text-[25px]">{title}</h4>}
        {startingBid && <p className="lg:text-[20px]">{`Starting Bid: ${startingBid}`}</p>}
        {estimatedValue && <p className="lg:text-[20px]">{`Estimated Value: ${estimatedValue}`}</p>}
        {link && (
          <div className="w-[90%] mx-auto text-center mt-6 mb-4 md:mr-auto md:text-left md:w-full">
            <CMSLink
              url={link.url}
              target={link.target}
              fullWidthMobile={false}
            >
              {link.title}
            </CMSLink>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleAuction;