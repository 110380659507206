import React from "react"
import LeftGraphic from '../../../images/left-graphic.svg'
import RightGraphic from '../../../images/right-graphics.svg'
import { filterCmsLinks } from "utils/helpers"

const TextWithGraphics = ({data, isHomePage }) => {
  const { text } = data
  return(
    <section className="bg-teal xl:relative xl:overflow-hidden">
      <div className="hidden xl:block  xl:absolute xl:bottom-[-50px] xl:left-[-25px] [1400px]:left-[0px] desktop:bottom-[0px] desktop:left-[0px]">
        <img className="xl:w-[150px] " src={LeftGraphic} alt="" />
      </div>
      <div className={`relative z-10 max-w-[1154px] w-90 mx-auto py-14  [&>h3]:text-2xl [&>p]:mb-6 [&>h3]:md:text-3xl [&>h3]:xl:text-5xl  [&>p]:xl:text-[20px] [&>p]:xl:w-5/6 [&>h3]:xl:w-5/6 xxl:max-w-main [&>h3]:xxl:text-[40px] ${isHomePage ? "xl:py-32 [&>h3]:xl:mb-8 [&>h3]:xxl:text-[40px]" : "xl:py-20 [&>h3]:xl:mb-1 [&>h3]:xxl:text-[40px]"} `} dangerouslySetInnerHTML={{ __html: filterCmsLinks(text) }}/>
      <div className="hidden xl:block xl:z-0 xl:absolute xl:top-[30px] xl:right-[10px]">
        <img className="xl:w-[550px]" src={RightGraphic} alt="" />
      </div>
    </section>
  )
}

export default TextWithGraphics
