import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import CMSLink from "../../shared/cms-link";

const FullWidthImage = ({ data }) => {
  const { image, link } = data
  return (
    <div className="relative z-10 bg-dark-grey max-w-main w-90 mx-auto py-16 flex-col md:flex md:gap-10 md:items-center md:justify-center xl:py-24">
      {image && (
        <GatsbyImage
          image={image.localFile.childImageSharp.gatsbyImageData}
          alt={image.altText}
        />
      )}
      {link && (
        <CMSLink url={link.url} target={link.target} className="mt-2 block">
          {link.title}
        </CMSLink>
      )}
    </div>
  );
};

export default FullWidthImage;
