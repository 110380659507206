export function validateRequired(validate, e, name = false, value = false) {
  // Is event passed in?
  if (e !== false) {
    name = e.target.name;
    value = e.target.value;
  }
  
  if (value !== '') {
    validate[name] = true
  } else {
    validate[name] = false
  }

  return validate
}

export function validateRequiredCheckbox(validate, e, name = false, value = false) {
  // Is event passed in?
  if (e !== false) {
    name = e.target.name;
    value = e.target.value;
  }
  
  if (value !== 'true') {
    validate[name] = true
  } else {
    validate[name] = false
  }
  
  return validate
}

export function validateEmail(validate, e) {
  // Must be valid email
  const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(e.target.value)) {
    validate.email = true
  } else {
    validate.email = false
  }
  
  return validate
}

export function validateFile(e, validate, setValidate, errors, setErrors) {
	const file = e.target && e.target.files[0]
	let status
	let error
	if (file) {
		const fileSize = file.size / 1024 / 1024; // in MiB
		if (fileSize > 1) {
			status = 'has-danger'
			error = 'File must not exceed 2mb.'
		} else {
      status = 'has-success'
      if (e.target.name === "file1") {
        error = "Please include 1 image."
      }
		}
	} 
  // else {
  //   if (e.target.name === "file1") {
  //     status = 'has-danger'
  //     error = `This file is required.`
  //   }
	// }
	
	setValidate({
		...validate,
		[e.target.name]: status
	})
	setErrors({
		...errors,
		[e.target.name]: error
	})
}