import React, { useState } from "react"
import Amplify, { API } from "aws-amplify"
import awsconfig from "aws-exports"
import scrollToElement from "scroll-to-element"
import { validateRequired, validateEmail } from "utils/validations"
import Dropdown from "./contact-form-dropdown"
import Button from "components/shared/button"

Amplify.configure(awsconfig)

const ContactForm = ({data}) => {
  const [values, setValues] = useState({
    name: '',
    email: '',
    address: '',
    website: '',
    company: '',
    dropdown1: '',
    dropdown2: '',
    dropdown3: '',
    message: ''
  })
  const [required, setRequired] = useState({
    name: '',
    email: '',
    address: '',
    website: '',
    company: '',
    dropdown1: '',
    dropdown2: '',
    dropdown3: '',
    message: ''
  })
  const [validate, setValidate] = useState({
    name: '',
    email: '',
    address: '',
    website: '',
    company: '',
    dropdown1: '',
    dropdown2: '',
    dropdown3: '',
    message: ''
  })
  const [success, setSuccess] = useState(false)
  const [sendingFormRequest, setSendingFormRequest] = useState(false)

  const {
    text,
    nameLabel,
    namePlaceholder,
    emailLabel,
    emailPlaceholder,
    addressLabel,
    addressPlaceholder,
    websiteLabel,
    websitePlaceholder,
    companyLabel,
    companyPlaceholder,
    dropdown1Label,
    dropdown1Placeholder,
    dropdown1Values,
    dropdown2Label,
    dropdown2Placeholder,
    dropdown2Values,
    dropdown3Label,
    dropdown3Placeholder,
    dropdown3Values,
    textAreaLabel,
    textAreaPlaceholder,
    thankYouMessage
  } = data

  const handleChange = (e, name=false, value=false) => {
    if (e !== false) {
      const { target } = e
      name = target.name
      value = target.value
    }

    // set required fields value
    if (required.hasOwnProperty(name)) {
      setRequired(required => ({
        ...required,
        [name]: value,
      }))
    }

    // Update value in state
    setValues(values => ({
      ...values,
      [name]: value,
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setSendingFormRequest(true)
    setSuccess(false)

    // Create form data ready for api wrapper call
    const formData = {}
    for (const key in values) {
      formData[key] = values[key]
    }

    let errors = false
    const validateObj = {}
    for (const key in validate) {
      if (required[key] === '' && key) {
        validateObj[key] = false
        errors = true
      } else {
        validateObj[key] = true
      }
      setValidate(validateObj)
    }

    // return if errors
    if (errors) {
      setSendingFormRequest(false)
      return
    }

    const apiName = 'TMCContactFormAPI'
    const path = '/contact'
    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: formData,
    }

    return API.get(apiName, path, myInit)
      .then(response => {
        // return response.data
        setSendingFormRequest(false)

        console.log(response)

        if (response.status === 200 && response.data.result === "success") {
          setSuccess(true)
          // console.log(values)
          // console.log(validate)

          scrollToElement("#vendor-success", {
            offset: -120,
            duration: 500
          });

          setValues({
            name: '',
            email: '',
            address: '',
            website: '',
            company: '',
            dropdown1: '',
            dropdown2: '',
            dropdown3: '',
            message: ''
          })
        }
      })
      // .catch(error => {
      //   setSendingFormRequest(false)
      //   setError(true)
      //   console.log(error)
      //   return error.response
      // })
  }

  return(
    <div className="bg-gradient-to-b from-dark-blue to-darker-blue py-10" id="vendor-form">
      <div className="container max-w-main w-90">
        {text &&
          <div
            className="[&>h3]:text-2xl [&>h3]:md:text-3xl md:mb-12 [&>h3]:xl:text-5xl [&>p]:xl:text-[20px] [&>p]:text-[20px] [&>h3]:xxl:text-[40px] "
            dangerouslySetInnerHTML={{ __html: text }}
          />
        }
        <form 
          onSubmit={e => {
            e.preventDefault()
            if (!sendingFormRequest) {
              handleSubmit(e)
            }
          }}
          noValidat
          className="pb-8 max-w-[950px]"
        > 
          <div className="md:flex md:-mx-4">
            <div className="md:basis-1/2 md:px-4 pb-4 md:pb-6">
              <label for="name" className="pb-2 block">{nameLabel}</label>
              <input 
                type="text" 
                name="name"
                id="name"
                value={values.name} 
                placeholder={namePlaceholder}
                onChange={e => {
                  handleChange(e)
                  validateRequired(validate, e)
                }}
                className="text-black p-3 w-full placeholder:text-light-grey"
              />
              {(!validate.name && validate.name !== '') &&
                <p className="text-pink">Your name is required</p>
              }
            </div>
            <div className="md:basis-1/2 md:px-4 pb-4 md:pb-6">
              <label for="email" className="pb-2 block">{emailLabel}</label>
              <input 
                type="email" 
                name="email"
                id="email"
                value={values.email} 
                placeholder={emailPlaceholder}
                onChange={e => {
                  handleChange(e)
                  validateRequired(validate, e)
                  validateEmail(validate, e)
                }}
                className="text-black p-3 w-full placeholder:text-light-grey"
              />
              {(!validate.email && validate.email !== '') &&
                <p className="text-pink">Your email is required</p>
              }
            </div>
          </div>
          <div className="md:flex md:-mx-4">
            <div className="md:basis-1/2 md:px-4 pb-4 md:pb-6">
              <label for="address" className="pb-2 block">{addressLabel}</label>
              <input 
                type="text" 
                name="address"
                id="address"
                value={values.address} 
                placeholder={addressPlaceholder}
                onChange={e => {
                  handleChange(e)
                  validateRequired(validate, e)
                }}
                className="text-black p-3 w-full placeholder:text-light-grey"
              />
              {(!validate.address && validate.address !== '') &&
                <p className="text-pink">Your address is required</p>
              }
            </div>
            <div className="md:basis-1/2 md:px-4 pb-4 md:pb-6">
              <label for="website" className="pb-2 block">{websiteLabel}</label>
              <input 
                type="text" 
                name="website"
                id="website"
                value={values.website} 
                placeholder={websitePlaceholder}
                onChange={e => {
                  handleChange(e)
                  validateRequired(validate, e)
                }}
                className="text-black p-3 w-full placeholder:text-light-grey"
              />
              {(!validate.website && validate.website !== '') &&
                <p className="text-pink">Your website is required</p>
              }
            </div>
          </div>
          <div className="md:flex md:-mx-4">
            <div className="md:basis-1/2 md:px-4 pb-4 md:pb-6">
              {/* <label for="company" className="pb-2 block">{companyLabel}</label> */}
              <input 
                type="text" 
                name="company"
                id="company"
                value={values.company} 
                // placeholder={companyPlaceholder}
                onChange={e => {
                  handleChange(e)
                  validateRequired(validate, e)
                }}
                className="text-black p-3 w-full placeholder:text-light-grey"
              />
              {(!validate.company && validate.company !== '') &&
                <p className="text-pink">Your company is required</p>
              }
            </div>
            <div className="md:basis-1/2 md:px-4 pb-4 md:pb-6">
              <Dropdown 
                name="dropdown1"
                id="dropdown1"
                label={dropdown1Label} 
                placeholder={dropdown1Placeholder} 
                dropdownValues={dropdown1Values}
                validate={validate}
                handleChange={handleChange}
                validateRequired={validateRequired}
                values={values}
              />
            </div>
          </div>
          <div className="md:flex md:-mx-4">
            <div className="md:basis-1/2 md:px-4 pb-4 md:pb-6">
              <Dropdown 
                name="dropdown2"
                label={dropdown2Label} 
                placeholder={dropdown2Placeholder} 
                dropdownValues={dropdown2Values}
                validate={validate}
                handleChange={handleChange}
                validateRequired={validateRequired}
                values={values}
              />
            </div>
            <div className="md:basis-1/2 md:px-4 pb-4 md:pb-6">
              <Dropdown 
                name="dropdown3"
                label={dropdown3Label} 
                placeholder={dropdown3Placeholder} 
                dropdownValues={dropdown3Values}
                validate={validate}
                handleChange={handleChange}
                validateRequired={validateRequired}
                values={values}
              />
            </div>
          </div>
          <div className="md:-mx-4">
            <div className="md:px-4 pb-4 md:pb-6">
              <label for="message" className="pb-2 block">{textAreaLabel}</label>
              <textarea 
                name="message"
                id="message"
                placeholder={textAreaPlaceholder} 
                value={values.message} 
                rows="8"
                onChange={e => {
                  handleChange(e)
                  validateRequired(validate, e)
                }}
                className="text-black p-3 w-full placeholder:text-light-grey"
              />
              {(!validate.message && validate.message !== '') &&
                <p className="text-pink">This field is required</p>
              }
            </div>
          </div>
          <Button className="mb-2 mt-0" type="submit">
            Submit
          </Button>
        </form>
        {success &&
          <div dangerouslySetInnerHTML={{ __html: thankYouMessage }} className="text-white text-2xl" id="vendor-success" />
        }
      </div>
    </div>
  )
}

export default ContactForm