import React, { useEffect, useRef } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import CMSLink from "../../shared/cms-link";

import { gsap } from 'utils/gsap'

const Sponsors = ({ data }) => {
  const { title, sponsors } = data;

  const sponsorsRef = useRef();
  const tl = useRef();
  useEffect(() => {
    const ctx = gsap.context(() => {
      tl.current = gsap.timeline({
        duration: .25,
        scrollTrigger: {
          trigger: sponsorsRef.current,
          toggleActions: "play none none none" ,
          // toggleActions: "restart pause reverse pause" ,
          start: "top center"

          // pin: true,   // pin the trigger element while active
          // start: "top top", // when the top of the trigger hits the top of the viewport
        }
      })
        .from('h3', {
        // .from(galleryRef.current, {
          opacity: 0,
          x: '-100px',
        })
        .from('.box', {
        // .from(galleryRef.current, {
          opacity: 0,
          x: '-100px',
          stagger: .25
        });
    }, sponsorsRef);
  }, []);
  return (
    <div ref={sponsorsRef} className="relative z-10 bg-dark-grey max-w-main w-90 mx-auto  md:py-8 xl:py-10">
      {title && <h3 className="text-3xl md:text-4xl py-4 md:py-8">{title}</h3>}
      {sponsors && (
        <div className="sm:flex sm:flex-wrap -mx-4">
          {sponsors.map((sponsor) => {
            return (
              <div className="sm:basis-1/2 md:basis-1/3 lg:basis-1/4 px-4 pb-8 box">
                {sponsor.link ? (
                  <CMSLink
                    url={sponsor.link.url}
                    target={sponsor.link.target}
                    type="normal"
                    className="text-mint underline"
                  >
                    <GatsbyImage
                      image={
                        sponsor.image?.localFile?.childImageSharp.gatsbyImageData
                      }
                      alt={sponsor.image?.altText}
                    />
                  </CMSLink>
                ) : (
                  <GatsbyImage
                    image={
                      sponsor.image?.localFile?.childImageSharp.gatsbyImageData
                    }
                    alt={sponsor.image?.altText}
                  />
                )}
                {sponsor.title && (
                  <div className="font-bold text-xl py-2">{sponsor.title}</div>
                )}
                {sponsor.link && (
                  <div>
                    <CMSLink
                      url={sponsor.link.url}
                      target={sponsor.link.target}
                      type="normal"
                      className="text-mint underline"
                    >
                      {sponsor.link.title}
                    </CMSLink>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Sponsors;