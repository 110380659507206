import React, { useEffect, useRef, useState } from "react";

import Arrow from "components/icons/arrow";
import { gsap } from "gsap";
import { filterCmsLinks } from "utils/helpers";

const Faqs = ({ data }) => {
  const { groups } = data;
  //? Grab each category from each group
  const categories = groups.map((category) => category.category);
  //? Set first category as active group
  const [activeTab, setActiveTab] = useState(categories[0]);
  return (
    <div className="relative z-10 bg-dark-grey">
      <div className="max-w-main w-90 mx-auto py-12 md:grid md:grid-cols-faqs grid-gap-faqs md:py-16">
      <FaqCategories
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        data={groups}
      />
      <FaqWrapper activeTab={activeTab} data={groups} />
    </div>
    </div>
  );
};

export default Faqs;

const FaqCategories = ({ activeTab, setActiveTab, data }) => {
  const [open, setOpen] = useState(false);
  const [category, setCategory] = useState(data[0].category);
  const toggleActive = (i) => {
    setCategory(i);
    setActiveTab(i);
    setOpen(false);
  };
  //* animations
  const menuRef = useRef();
  const q = gsap.utils.selector(menuRef);
  useEffect(() => {
    menuRef.current = gsap
      .timeline({ ease: "power1.out" })
      .to(q(".faq-dropdown"), {
        opacity: 1,
        visibility: "visible",
      })
      .to(
        q("svg"),
        {
          rotation: 180,
        },
        "<"
      );
  }, []);
  useEffect(() => {
    if (!open) {
      menuRef.current.reverse();
    } else {
      menuRef.current.play();
    }
  }, [open]);
  return (
    <>
      <div
        className="flex relative w-full p-4 border-2 border-mint bg-white  flex-row items-center justify-between md:hidden"
        onClick={() => setOpen(!open)}
        ref={menuRef}
      >
        <p className="text-black uppercase">{category}</p>
        <div className="[&>svg]:w-[20px] [&>svg]:h-[20px] [&>svg]:fill-black">
          <Arrow />
        </div>
        <div className="faq-dropdown bg-black absolute left-0 right-0 bottom-[-6.5rem] w-full z-10 cursor-pointer ">
          <section className="flex flex-col gap-4 py-4 px-4">
            {data.map((titles, i) => (
              <p
                role="button"
                key={i}
                tabIndex={i}
                onClick={() => toggleActive(titles.category)}
                onKeyDown={() => toggleActive(titles.category)}
                className={`uppercase ${
                  category === titles.category ? "active" : ""
                }`}
              >
                {titles.category}
              </p>
            ))}
          </section>
        </div>
      </div>
      <div>
        <section className="hidden md:flex md:flex-col md:gap-2">
          {data.map((titles, i) => (
            <p
              role="button"
              key={i}
              tabIndex={i}
              onClick={() => toggleActive(titles.category)}
              onKeyDown={() => toggleActive(titles.category)}
              className={`uppercase font-display text-mint font-bold hover:text-white text-[22px] ${
                category === titles.category ? "active" : ""
              }`}
            >
              {titles.category}
            </p>
          ))}
        </section>
      </div>
    </>
  );
};

const FaqWrapper = ({ activeTab, data }) => {
  const faqWrapperRef = useRef();
  const q = gsap.utils.selector(faqWrapperRef);
  useEffect(() => {
    faqWrapperRef.current = gsap
      .timeline({ ease: "power1.out" })
      .to(q(".singleFaq"), {
        opacity: 1,
        y: 0,
        duration: 0.5,
        stagger: 0.5,
        delay: 0.5,
      });
  }, [activeTab]);
  return (
    <div className="my-6 md:my-0">
      {data.map((category, i) => {
        return (
          <React.Fragment key={i}>
            {activeTab === category.category ? (
              <div>
                <h3 className="text-mint text-[20px] md:hidden">
                  {category.category}
                </h3>
                <div className="flex flex-col gap-6" ref={faqWrapperRef}>
                  {category.faq.map((question, i) => {
                    const id = `${category.category}${i}`;
                    return <SingleFaq data={question} key={i} />;
                  })}
                </div>
              </div>
            ) : (
              ""
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const SingleFaq = ({ data }) => {
  const [open, setOpen] = useState(false);
  const faqRef = useRef();
  const q = gsap.utils.selector(faqRef);
  useEffect(() => {
    faqRef.current = gsap
      .timeline({ ease: "power1.out" })
      .to(q(".answer"), {
        visibility: "visible",
        height: "auto",
      })
      .to(
        q("svg"),
        {
          rotation: 180,
        },
        "<"
      );
  }, []);
  useEffect(() => {
    if (!open) {
      faqRef.current.reverse();
    } else {
      faqRef.current.play();
    }
  }, [open]);
  return (
    <div ref={faqRef} onClick={() => setOpen(!open)} className="singleFaq">
      <div className="flex justify-between items-center pt-5 border-t-2 border-mint z-[5] relative cursor-pointer	 ">
        <p className="text-[20px] font-bold font-display ">{data.question}</p>
        <div className="[&>svg]:w-[20px] [&>svg]:h-[20px] [&>svg]:fill-white">
          <Arrow />
        </div>
      </div>
      <div className="answer overflow-hidden">
        <div
          className="py-1 lg:text-[18px] lg:w-[90%]"
          dangerouslySetInnerHTML={{ __html: filterCmsLinks(data.answer) }}
        />
      </div>
    </div>
  );
};
