import React, { useEffect, useState, useRef } from "react";
import ReactPlayer from "react-player";
import { GatsbyImage } from "gatsby-plugin-image";

import { useOnScreen, defaultTransition } from "utils/helpers";
import CMSLink from "components/shared/cms-link";

const LandingHero = (props) => {
  const { desktopImage, mobileImage, text, backgroundVideo, link } = props.data;

  const [playing, setPlaying] = useState(false);
  // const [loaded, setLoaded] = useState(false)
  const [videoError, setVideoError] = useState(false);
  const ref = useRef();
  const isVisible = useOnScreen(ref);

  const onVideoError = () => {
    setPlaying(false);
    setVideoError(true);
  };

  useEffect(() => {
    setPlaying(true);
  }, []);

  // ${loaded ? "opacity-100" : "opacity-0"} delay-300 duration-500
  return (
    <section
      className={`h-mob w-full aspect-[96/43] relative overflow-hidden transition-all md:h-[500px] xl:h-screen xl:max-h-[500px] after:content:[''] after:absolute after:inset-0 after:w-full after:h-full after:bg-dark-overlay after:z-30	`}
    >
        {desktopImage && (
        <div className="desktop h-full w-full absolute pointer-events-none z-0">
        <GatsbyImage
          image={desktopImage.localFile.childImageSharp.gatsbyImageData}
          alt={desktopImage.altText}
          className="object-cover h-full w-full"
        />
        </div>
      )}
      {mobileImage && (
        <div className="mobile h-full w-full absolute pointer-events-none z-0">
        <GatsbyImage
          image={mobileImage.localFile.childImageSharp.gatsbyImageData}
          alt={desktopImage.altText}
          className="object-cover h-full w-full"
        />
        </div>
      )}

      {backgroundVideo && !videoError && (
        <ReactPlayer
          url={backgroundVideo}
          config={{
            youtube: {
              playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 0,
                controls: 0,
                autohide: 1,
                rel: 0,
                modestbranding: 1,
                loop: 1,
                mute: 1,
                fs: 0,
                playsinline: 1,
              },
              // onUnstarted: onVideoError
            },
          }}
          controls={false}
          className="video-background pointer-events-none z-20 after:absolute after:top-0 after:bottom-0 after:right-0 after:left-0 after:bg-black after:opacity-[38%] after:z-40"
          width="100%"
          height="100%"
          playing={playing}
          muted={true}
          loop={true}
          playsinline={true}
          onError={onVideoError}
          onPause={onVideoError}
        />
      )}
      <div
        className={`absolute z-50 w-full h-full inset-0	flex items-center justify-center  md:bottom-36 left-0 top-0  text-center  md:w-auto ${defaultTransition(
          isVisible
        )}`}
        ref={ref}
      >
        {/* [&>h1]:xl:text-[55px] [&>h1]:xxl:text-[80px] */}
        <div className="max-w-main w-90 text-left font-bold">
          {text && (
            <div
              className="max-w-[750px] w-90 text-left font-bold pt-5 no-underline [&>h1]:text-3xl  [&>h2]:text-mint  [&>h2]:text-lg [&>h2]:leading-6 [&>h3]:normal-case [&>h3]:text-md [&>h1]:md:text-5xl [&>h2]:md:text-2xl  [&>h3]:md:text-xl [&>h1]:xl:text-7xl [&>h2]:xl:text-4xl [&>h2]:xl:my-2 [&>h3]:xl:text-2xl [&>h3]:xl:mt-8 [&>h1]:xxl:text-[60px]"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
          {link && (
            <div>
              <CMSLink
                url={link.url}
                target={link.target}
                fullWidthMobile={false}
                heroButton={true}
              >
                {link.title}
              </CMSLink>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default LandingHero;

// const LandingHeroText = ({ text }) => {

// }
