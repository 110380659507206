import React, { useEffect, useRef } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import CMSLink from "components/shared/cms-link";
import { filterCmsLinks } from "utils/helpers";

import { gsap } from "utils/gsap";

import LiArrow from "images/tick.svg";

const TextWithWhiteBox = ({ data }) => {
  const {
    background,
    smallerImage,
    styledBulletPoints,
    image,
    backgroundImage,
    textPosition,
    text,
    link,
  } = data;
  const imageWithTextRef = useRef();
  const tl = useRef();
  useEffect(() => {
    const ctx = gsap.context(() => {
      tl.current = gsap
        .timeline({
          duration: 0.4,
          scrollTrigger: {
            trigger: imageWithTextRef.current,
            start: "top 75%",
          },
        })
        .from(".text", {
          opacity: 0,
          x: "-100px",
          stagger: 0.25,
        })
        .from(
          ".image",
          {
            opacity: 0,
            x: "-100px",
            stagger: 0.25,
          },
          "<"
        );
    }, imageWithTextRef);
  }, []);
  return (
    <div
      className={`relative ${background === "black" && "bg-dark-grey"} 
      ${background === "white" && "bg-white"}
       ${background === "green" && "bg-teal"} ${
        background === "gradient" && "gradient-1"
      }`}
    >
      <div
        ref={imageWithTextRef}
        className="relative z-10 max-w-main w-90 mx-auto py-16 md:flex md:gap-10 md:items-center md:flex-row md:justify-center xl:py-24"
      >
        <div
          className={`text md:w-1/2 md:h-full ${
            textPosition === "left" ? "order-1" : "order-2"
          }`}
        >
          {text && (
            <div
              className={`${background === "white" && "text-black"}
              ${
                styledBulletPoints === true && "textBlockStyles"
              } [&>h3]:text-2xl [&>p]:pb-6 [&>ul]:list-disc [&>ul]:pl-6  [&>ul]:pb-6  [&>ul>li>h4]:pb-0 [&>h4]:pt-4  [&>h3]:md:text-3xl [&>ul]:xl:text-[20px]   [&>ul>li>h4]:xl:text-[30px] [&>h4]:xl:text-[30px] [&>p]:xl:text-[20px]  [&>h3]:xl:text-[40px] `}
              dangerouslySetInnerHTML={{ __html: filterCmsLinks(text) }}
            />
          )}

          {link && (
            <CMSLink url={link.url} target={link.target} className="mt-6 block">
              {link.title}
            </CMSLink>
          )}
        </div>
        {image && (
          <div
            className={`image mt-12 md:w-1/2 md:mt-0 ${
              textPosition === "left" ? "order-2" : "order-1"
            }
            ${smallerImage === true && "max-w-[492px]"}
            `}
          >
            <GatsbyImage
              image={image.localFile.childImageSharp.gatsbyImageData}
              alt={image.altText}
            />
          </div>
        )}
      </div>
      {backgroundImage && (
        <div className="inset-0 h-full w-full object-cover absolute pointer-events-none z-0 after:content:[''] after:absolute after:inset-0 after:w-full after:h-full after:bg-dark-overlay after:z-30">
          <GatsbyImage
            image={backgroundImage.localFile.childImageSharp.gatsbyImageData}
            alt={backgroundImage.altText}
            className="h-full w-full"
          />
        </div>
      )}
    </div>
  );
};

export default TextWithWhiteBox;
