import React from 'react'

const Tick = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="9" viewBox="0 0 10 9" fill="none">
        <path d="M9.24292 0.504272L2.5176 8.01177L0.5 5.76177" stroke="#00FFA3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default Tick