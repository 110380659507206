import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import CMSLink from "../../shared/cms-link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft, faAnglesRight, faAnglesLeft } from "@fortawesome/free-solid-svg-icons"
import NewsCard from "../../shared/news-card"

export default function NewsListing({data}) {
  const allNews = useStaticQuery(graphql`
    {
      allWpNewsArticle {
        edges {
          node {
            title
            databaseId
            uri
            date
            acfNewsArticle {
              page
              excerpt
              featuredImage {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData( quality: 100, placeholder: NONE, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const getPage = () => {
    if (typeof window !== "undefined") {
      const urlParams = new URLSearchParams(window.location.search);
      const page = urlParams.get('page') ? (urlParams.get('page') - 1) : 0
  
      return page
    }
  }

  const paginate = (direction) => {
    if (typeof window !== "undefined") {
      const page = getPage()
      const url = window.location.pathname 
      let num
  
      if (direction === "next") {
        num = page + 2 <= numPages ? page + 2 : 0
      }
  
      if (direction === "prev") {
        num = (page !== 0 && page - 2 > 0) ? page - 2 : 0
      }

      return num === 0 ? url : `${url}?page=${num}` 
    }
    return null
  }

  const { 
    title,
    display,
    news,
    link
  } = data

  let newsItems = allNews.allWpNewsArticle.edges 

  if (display === "select") {
    let ids = []
    news.forEach(id => {
      ids.push(id.databaseId)
    });
    newsItems = newsItems.filter(i => ids.includes(i.node.databaseId))
    newsItems.sort((a, b) => ids.indexOf(a.node.databaseId) - ids.indexOf(b.node.databaseId));
  } 

  const postsPerPage = 12
  const numPages = Math.ceil(newsItems.length / postsPerPage)
  const page = getPage()
  newsItems = newsItems.slice(page * postsPerPage, (page * postsPerPage) + postsPerPage)

  return(
    <div className="bg-white py-8 md:py-16">
      <div className="max-w-main w-90 mx-auto">
        {title && (
          <h2 className="text-[25px] m-0 pb-4 md:text-[35px] xl:text-[50px] text-black">
            {title}
          </h2>
        )}
        <div className="flex flex-wrap -mx-4">
          {newsItems?.map(({node}, i) => {
            return <NewsCard data={node} key={`news${i}`} />
          })}
        </div>
        {numPages > 1 && (
          <div className="text-black flex justify-center w-full">
            <ul className="flex">
              {getPage() !== 0 && (
                <>
                  <li>
                    <Link to={typeof window !== "undefined" ? window.location.pathname : ""} className="p-2 text-black">
                      <FontAwesomeIcon icon={faAnglesLeft} />
                    </Link>
                  </li>
                  <li>
                    <Link to={paginate("prev")} className="p-2 text-black">
                      <FontAwesomeIcon icon={faAngleLeft} />
                    </Link>
                  </li>
                </>
              )}

              {[...Array(numPages)].map((num, i) => {
                return(
                  <li key={`page${i}`}>
                    <Link 
                      to={typeof window !== "undefined" ? `${window.location.pathname}?page=${i+1}` : ""} 
                      className={`p-2 text-black ${page === i ? "font-bold text-purple" : ""}`}
                    >
                      {i+1}
                    </Link>
                  </li>
                )
              })}

              {(getPage() + 1 !== numPages) &&
                <>
                  <li>
                    <Link to={paginate("next")} className="p-2 text-black">
                      <FontAwesomeIcon icon={faAngleRight} />
                    </Link>
                  </li>
                  <li>
                    <Link to={`${typeof window !== "undefined" ? window.location.pathname : ""}?page=${numPages}`} className="p-2 text-black">
                      <FontAwesomeIcon icon={faAnglesRight} />
                    </Link>
                  </li>
                </>
              }
            </ul>
          </div>
        )}
        {link && (
          <div className="pt-4 md:pt-8">
            <CMSLink
              url={link.url}
              target={link.target}
              type="button"
            >
              {link.title}
            </CMSLink>
          </div>
        )}
      </div>
    </div>
  )
}