import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, A11y } from "swiper";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

import { filterCmsLinks } from "utils/helpers";
import { GatsbyImage } from "gatsby-plugin-image";

const FullWidthCarousel = ({ data }) => {
  const {
    slide,
    size,
    mainBackgroundImage,
    reviewSlider,
    desktopImage,
    mobileImage,
  } = data;
  return (
    <div className="max-w-xxl  mx-auto bg-dark-grey relative">
      <div>
        {slide?.length === 1 ? (
          <SingleSlide data={slide[0]} size={size} />
        ) : (
          <Swiper
            // pagination={true}
            modules={[Autoplay, Pagination]}
            className="fullWidthSlider"
            loop={true}
            speed={1000}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
              type: "bullets",
            }}
          >
            {slide.map((item, i) => {
              return (
                <SwiperSlide key={i}>
                  <SingleSlide
                    data={item}
                    size={size}
                    isReviewSlider={reviewSlider === "yes" && true}
                    mainBackgroundImage={mainBackgroundImage}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </div>
      {mainBackgroundImage === "true" && (
        <>
          {desktopImage && (
            <div
              className={`hidden md:block h-full w-full object-cover absolute inset-0 top-0 left-0 bottom-0 right-0 pointer-events-none z-0 after:content:[''] after:absolute after:inset-0 after:w-full after:h-full after:bg-dark-overlay after:z-30 ${
                size === "smaller" ? "md:h-[400px]" : "md:h-[600px]"
              }`}
            >
              <GatsbyImage
                image={desktopImage.localFile.childImageSharp.gatsbyImageData}
                alt={desktopImage.altText}
                className="w-full h-full"
              />
            </div>
          )}
          {mobileImage && (
            <div
              className={`md:hidden  w-full object-cover absolute inset-0 top-0 left-0 bottom-0 right-0 pointer-events-none z-0 after:content:[''] after:absolute after:inset-0 after:w-full after:h-full after:bg-dark-overlay after:z-30 ${
                size === "smaller" ? "h-[360px]" : "h-[500px]"
              }`}
            >
              <GatsbyImage
                image={mobileImage.localFile.childImageSharp.gatsbyImageData}
                alt={desktopImage.altText}
                className="w-full h-full"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FullWidthCarousel;

const SingleSlide = ({ data, size, isReviewSlider, mainBackgroundImage }) => {
  const { title, text, desktopImage, mobileImage, reviewer } = data;
  return (
    <div
      className={`max-w-xxl cursor-pointer z-[20]  relativew-full  py-20 text-center  flex justify-center items-center s:py-28  ${
        size === "smaller" ? "h-[360px] md:h-[400px]" : "h-[500px] md:h-[600px]"
      }`}
    >
      <div className="max-w-main relative z-[11] w-[90%] mx-auto ">
        {title && (
          <h4 className={`relative z-10 text-[25px] s:text-2xl md:text-[30px] md:mb-6 xl:text-[35px] xl:mb-6 ${isReviewSlider && 'max-w-[950px] xl:mx-auto xl:leading-10'}`}>
            {title}
          </h4>
        )}
        {text && (
          <div
            className="[&>p]:text-[14px] [&>p>span]:mt-4 [&>p>span]:block  max-w-[1000px] mx-auto [&>p]:s:text-[16px] [&>p]:md:text-[25px]"
            dangerouslySetInnerHTML={{ __html: filterCmsLinks(text) }}
          />
        )}
        {isReviewSlider && reviewer && (
          <p className="mt-3 text-[14px] s:text-[16px] md:text-[18px]">
            {reviewer}
          </p>
        )}
      </div>
      {mainBackgroundImage === "false" && (
        <>
          {desktopImage && (
            <div
              className={`hidden md:block h-full w-full object-cover absolute inset-0 top-0 left-0 bottom-0 right-0 pointer-events-none z-10 after:content:[''] after:absolute after:inset-0 after:w-full after:h-full after:bg-dark-overlay after:z-30 ${
                size === "smaller" ? "md:h-[400px]" : "md:h-[600px]"
              }`}
            >
              <GatsbyImage
                image={desktopImage.localFile.childImageSharp.gatsbyImageData}
                alt={desktopImage.altText}
                className="w-full h-full"
              />
            </div>
          )}
          {mobileImage && (
            <div
              className={`md:hidden  w-full object-cover absolute inset-0 top-0 left-0 bottom-0 right-0 pointer-events-none z-10 after:content:[''] after:absolute after:inset-0 after:w-full after:h-full after:bg-dark-overlay after:z-30 ${
                size === "smaller" ? "h-[360px]" : "h-[500px]"
              }`}
            >
              <GatsbyImage
                image={mobileImage.localFile.childImageSharp.gatsbyImageData}
                alt={desktopImage.altText}
                className="w-full h-full"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
