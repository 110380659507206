import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, A11y } from "swiper";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

import { filterCmsLinks } from "utils/helpers";
import { GatsbyImage } from "gatsby-plugin-image";

const GridCarousel = ({ data }) => {
  const {
    slide,
    size,
    mainBackgroundImage,
    desktopImage,
    mobileImage,
  } = data;
  return (
    <div className="max-w-xxl  mx-auto bg-dark-grey relative">
      <div>
        {slide?.length === 1 ? (
          <SingleSlide data={slide[0]} size={size} />
        ) : (
          <Swiper
            modules={[Autoplay, Pagination]}
            className="fullWidthSlider"
            loop={true}
            speed={1000}
            // autoplay={{
            //   delay: 2500,
            //   disableOnInteraction: false,
            // }}
            pagination={{
              clickable: true,
              type: "bullets",
            }}
          >
            {slide.map((item, i) => {
              return (
                <SwiperSlide key={i}>
                  <SingleSlide
                    data={item}
                    mainBackgroundImage={mainBackgroundImage}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </div>
        <>
          {desktopImage && (
            <div
              className={`hidden md:block h-full w-full object-cover absolute inset-0 top-0 left-0 bottom-0 right-0 pointer-events-none z-0 after:content:[''] after:absolute after:inset-0 after:w-full after:h-full after:bg-dark-overlay after:z-30`}
            >
              <GatsbyImage
                image={desktopImage.localFile.childImageSharp.gatsbyImageData}
                alt={desktopImage.altText}
                className="w-full h-full"
              />
            </div>
          )}
          {mobileImage && (
            <div
              className={`md:hidden  w-full object-cover absolute inset-0 top-0 left-0 bottom-0 right-0 pointer-events-none z-0 after:content:[''] after:absolute after:inset-0 after:w-full after:h-full after:bg-dark-overlay after:z-30`}
            >
              <GatsbyImage
                image={mobileImage.localFile.childImageSharp.gatsbyImageData}
                alt={desktopImage.altText}
                className="w-full h-full"
              />
            </div>
          )}
        </>
      
    </div>
  );
};

export default GridCarousel;

const SingleSlide = ({ data }) => {
  const { title, text } = data;
  return (
    <div
      className={`max-w-xxl cursor-pointer z-[20]  relativew-full  py-20 md:text-center  flex justify-center items-center s:py-28 h-[500px] md:h-[600px]`}
    >
      <div className="max-w-xxl relative z-[11] w-[90%] mx-auto">
        {title && (
          <h4 className={`relative z-10  text-[25px] leading-[2.85rem] md:text-[30px] md:mb-6 xl:text-[40px] xl:mb-6 lg:max-w-[850px] lg:mx-auto `}>
            {title}
          </h4>
        )}
        {text && (
          <div
            className="[&>ul]:list-disc text-left md:flex md:items-center md:justify-center [&>ul]:flex [&>ul]:flex-col [&>ul]:gap-4 [&>ul]:sm:flex-row [&>ul]:sm:gap-[3rem] [&>ul]:md:text-[18px] [&>ul]:md:gap-[6rem] [&>ul]:pl-12 [&>ul]:md:pl-0 [&>ul]:md:max-w-[80%]"
            dangerouslySetInnerHTML={{ __html: filterCmsLinks(text) }}
          />
        )}
      </div>
    </div>
  );
};
