import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import SingleAuction from "components/shared/single-auction";
import { StaticImage } from "gatsby-plugin-image";

const AuctionListing = ({ data }) => {
  const { backgroundGraphics } = data
  const { auction } = useStaticQuery(graphql`
    query AuctionQuery {
      auction: allWpAuction {
        edges {
          node {
            title
            acfAuctions {
              details {
                estimatedValue
                startingBid
                image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 500
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
                link {
                  target
                  title
                  url
                }
              }
            }
          }
        }
      }
    }
  `);
  const auctionData = auction?.edges;
  return (
    <div className="relative z-10 bg-dark-grey">
      <div className="max-w-main w-90 py-12 mx-auto flex flex-col gap-12 md:grid md:grid-cols-2 md:gap-4 xl:py-20 xl:gap-y-14">
        {auctionData.map((auction, i) => (
          <SingleAuction data={auction.node} key={i} index={i + 1} />
        ))}
      </div>
      <div className={`${backgroundGraphics === 'On' ? 'hidden lg:block' : 'hidden'} absolute inset-0 top-0 left-0 bottom-0 right-0 w-full h-full text-center `} >
        <StaticImage src='../../../images/background-images.png' alt="" className=" mx-auto" />
      </div>
    </div>
  );
};

export default AuctionListing;
