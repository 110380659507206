import React, { useEffect } from "react";
import Marquee from "react-fast-marquee";

const TickerText = ({ data }) => {
  const { headlines } = data;
  return (
    <section className={`bg-white py-4 overflow-hidden z-10 relative`}>
      <Marquee speed={80}>
        {headlines?.map((headline, i) => {
          return (
            <span
              className="text-black text-center pr-32 inline-block  lg:text-[20px]"
              dangerouslySetInnerHTML={{ __html: headline.text }}
              key={i}
            />
          );
        })}
      </Marquee>
    </section>
  );
};

export default TickerText;
