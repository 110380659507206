import React, { useEffect, useRef } from "react";
import CMSLink from "../../shared/cms-link";
import SingleVendor from "components/shared/single-vendor";
import { graphql, useStaticQuery } from "gatsby";

import { gsap } from 'utils/gsap'

const Vendors = ({ data }) => {
  const { vendorslink, vendorstext } = data
  const { vendors } = useStaticQuery(graphql`
    query VendorsQuery {
      # allVendorsFetched is sourced from gatsby-node, to work around 100 node cap and perPage breaking images
      vendors: allVendorsFetched(limit: 1000) {
        edges {
          node {
            title
            acfVendors {
              details {
                name
                link {
                  target
                  title
                  url
                }
                image {
                  altText
                }
              }
            }
            # created from gatsby-plugin-remote-images
            localImage {
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  quality: 90
                  placeholder: NONE
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
  `);
  const vendorCards = vendors?.edges
  const vendorsRef = useRef();
  const tl = useRef();
  useEffect(() => {
    const ctx = gsap.context(() => {
      tl.current = gsap.timeline({
        duration: .25,
        scrollTrigger: {
          trigger: vendorsRef.current,
          toggleActions: "play none none none",
          start: "top 75%"
        }
      })
        .from('.text', {
          opacity: 0,
          x: '-100px',
        })
        .from('.vendor', {
          opacity: 0,
          x: '-100px',
          stagger: .15
        });
    }, vendorsRef);
  }, []);
  return (
   <div className="relative z-10 bg-dark-grey">
     <div ref={vendorsRef} className="max-w-main w-90 mx-auto py-14 xl:py-20 ">
      <div className="text">
        {vendorstext && (
          <div
            className="[&>h3]:text-2xl [&>h3]:md:text-3xl md:mb-12 [&>h3]:xl:text-5xl [&>p]:my-4   [&>h3]:xxl:text-[40px] xl:max-w-[1000px] "
            dangerouslySetInnerHTML={{ __html: vendorstext }}
          />
        )}
        {vendorslink && (
          <CMSLink
            url={vendorslink.url}
            target={vendorslink.target}
            className="mt-12 block w-80 text-center mx-auto md:mt-0 md:w-full"
          >
            {vendorslink.title}
          </CMSLink>
        )}
      </div>
      {vendorCards && (
        <div className="mt-20 sm:flex sm:flex-wrap -mx-4">
          {vendorCards.map((vendor, i) => {
            return <SingleVendor data={vendor.node} key={i} />;
          })}
        </div>
      )}
    </div>
   </div>
  );
};

export default Vendors;