import React, { useEffect, useRef } from "react";
import SingleSpeaker from "components/shared/single-speaker";
import { graphql, useStaticQuery } from "gatsby";
import CMSLink from "../../shared/cms-link";
import { gsap } from 'utils/gsap'

const Speakers = ({data}) => {
  const { display, speakers, link } = data
  const { allSpeakers } = useStaticQuery(graphql`
    query SpeakerQuery {
      allSpeakers: allWpExpert {
        edges {
          node {
            databaseId
            title
            acfExperts {
              details {
                company
                expertIn
                image {
                  altText
                  caption
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 400
                        quality: 90
                        placeholder: NONE
                        layout: CONSTRAINED
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const speakersRef = useRef();
  const tl = useRef();
  useEffect(() => {
    const ctx = gsap.context(() => {
      tl.current = gsap.timeline({
        duration: .4,
        scrollTrigger: {
          trigger: speakersRef.current,
          toggleActions: "play none none none" ,
          // start: "top top"
        }
      })
        .from('.single-speaker', {
          opacity: 0,
          x: '-100px',
          stagger: 0.25
        });
    }, speakersRef);
  }, []);

  let items = allSpeakers.edges
  if (display === "select") {
    let ids = []
    speakers.forEach(id => {
      ids.push(id.databaseId)
    });
    items = items.filter(i => ids.includes(i.node.databaseId))
    items.sort((a, b) => ids.indexOf(a.node.databaseId) - ids.indexOf(b.node.databaseId));
  } 

  return (
    <div ref={speakersRef} className="relative z-10 bg-dark-grey">
      <div className="max-w-main w-90 mx-auto">
        {items && (
          <div className="py-10 flex flex-col sm:grid sm:grid-cols-2 sm:py-16 md:grid-cols-3 lg:grid-cols-4 grid-gap-speakers xl:py-20">
            {items.map((card, i) => (
              <SingleSpeaker data={card.node} key={i} />
              ))}
          </div>
        )}
        {link && (
          <div className="pb-16">
            <CMSLink
                url={link.url}
                target={link.target}
                type="button"
              >
                {link.title}
              </CMSLink>
            </div>
          )}
        </div>
    </div>
  );
};

export default Speakers;
