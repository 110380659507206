import React from 'react'

const SingleLineText = ({ data }) => {
    const { text } = data 
  return (
    <div className='bg-lighter-green relative z-10'>
    <div className="max-w-main w-90 mx-auto py-5">
      <div>
        {text &&
          <div className="text-center text-black [&>a]:block [&>p>a]:text-black [&>p>a]:underline [&>p>a]:hover:text-blue" 
          dangerouslySetInnerHTML={{ __html: text }} 
        />
        }
      </div>
    </div>
  </div>
  )
}

export default SingleLineText