import React, { useEffect, useRef, useState } from "react";

import { gsap } from "gsap";
import Arrow from "components/icons/arrow";
import CMSLink from "./cms-link";
import Tick from "components/icons/tick";
import GoldTick from "components/icons/gold-tick";

const SingleTicket = ({ data, order }) => {
  const [open, setOpen] = useState(false);
  const {
    link,
    description,
    markdownPrice,
    packageValue,
    price,
    ticketOptions,
    ticketOptionTitle,
    title,
    notOnSaleText,
    ticketFeatures,
    titleLink,
    secondLink,
    ticketPackageLink,
  } = data.acfTickets;

  const ticketRef = useRef();
  const q = gsap.utils.selector(ticketRef);
  useEffect(() => {
    ticketRef.current = gsap
      .timeline({ ease: "power1.out" })
      .to(q(".ticketFeatures"), {
        visibility: "visible",
        height: "auto",
        opacity: 1,
      })
      .to(q(".arrowIcon"), {
        rotation: 180,
      })
      .from(
        q("li"),
        {
          opacity: 0,
          y: "20px",
          stagger: 0.15,
        },
        "<"
      );
  }, []);
  useEffect(() => {
    if (!open) {
      ticketRef.current.reverse();
    } else {
      ticketRef.current.play();
    }
  }, [open]);

  return (
    <div>
      {ticketOptions === "yes" ? (
        <div
          className={`${
            order === 2 ? "bg-mint" : "bg-white"
          } w-full text-black text-center py-2 text-[24px]`}
        >
          {ticketPackageLink ? (
            <CMSLink
              url={ticketPackageLink.url}
              target={ticketPackageLink.target}
              noStyle
              type="normal"
              className="text-black underline"
            >
              <p className="font-display font-black">
                {ticketOptionTitle?.toUpperCase()}
              </p>
            </CMSLink>
          ) : (
            <p>
              <strong className="font-display font-black ">
                {ticketOptionTitle && <>{ticketOptionTitle?.toUpperCase()}</>}
              </strong>
            </p>
          )}
        </div>
      ) : (
        ""
      )}

      <div
        className={`pb-8 border border-white ${
          ticketOptions === "no" ? "border-t-1" : "border-t-0"
        }`}
      >
        <div className="px-4 text-center">
          {title && (
            <>
              {titleLink ? (
                <CMSLink
                  url={titleLink.url}
                  target={titleLink.target}
                  noStyle
                  type="normal"
                  className="underline"
                >
                  <h3 className="font-display text-[25px] mb-0 pb-0 pt-8">
                    <strong
                      className={`${order === 2 && "text-mint"} font-display`}
                    >
                      {title}
                    </strong>
                  </h3>
                </CMSLink>
              ) : (
                <h3 className="font-display text-[25px] mb-0 pb-0 pt-8">
                  <strong
                    className={`${order === 2 && "text-mint"} font-display`}
                  >
                    {title}
                  </strong>
                </h3>
              )}
            </>
          )}
          {description && <p className="my-3">{description}</p>}
          {markdownPrice && (
            <p className="line-through text-[#D8D8D8] text-[16px]">{`$${markdownPrice}`}</p>
          )}
          {packageValue ? (
            <h5
              className={`font-bold font-display text-[16px] mt-8 ${
                order === 2 && "text-mint"
              }`}
            >{`${packageValue}`}</h5>
          ) : (
            <h5 className="font-bold font-display text-[16px] mt-8 opacity-0">{`-`}</h5>
          )}
          {notOnSaleText && (
            <h4 className="text-[35px] my-[1rem] pb-0 leading-[3rem]">
              {notOnSaleText}
            </h4>
          )}
          {price && (
            <h4
              className={`text-[50px] my-[1rem] pb-0 leading-[3rem] ${
                order === 2 && "text-mint"
              }`}
            >{`$${price}`}</h4>
          )}
          {ticketFeatures && (
            <div
              className="mt-8 mb-4 cursor-pointer"
              ref={ticketRef}
              onClick={() => setOpen(!open)}
            >
              <p className="flex items-center gap-2 text-center justify-center font-display font-black">
                SEE FEATURES{" "}
                <span className="arrowIcon [&>svg]:w-[15px] [&>svg]:h-[15px] [&>svg]:fill-white">
                  <Arrow />
                </span>
              </p>
              <ul className="ticketFeatures overflow-hidden opacity-0 h-[0] ">
                {ticketFeatures.map((item, i) => {
                  return (
                    <>
                      {item.name === "Exclusive MINT Drop Giveaway" ? (
                        <li
                          key={i}
                          className={`flex items-center text-left justify-start gap-2 text-[14px] py-2 text-gold`}
                        >
                          <span>
                            <GoldTick />
                          </span>
                          {item.name}
                        </li>
                      ) : (
                        <li
                          key={i}
                          className={`flex items-center text-left justify-start gap-2 text-[14px] py-2`}
                        >
                          <span>
                            <Tick />
                          </span>
                          {item.name}
                        </li>
                      )}
                    </>
                  );
                })}
              </ul>
            </div>
          )}
          {link && (
            <div className="w-[80%] mx-auto mt-8 [&>a]:mx-auto">
              <CMSLink
                url={link.url}
                target={link.target}
                className="mt-2 block"
              >
                {link.title}
              </CMSLink>
            </div>
          )}
          {secondLink && (
            <div className="text-center w-full mt-3 text-white underline">
              <CMSLink
                url={secondLink.url}
                target={secondLink.target}
                noStyle
                type="normal"
                className="text-white underline"
              >
                {secondLink.title}
              </CMSLink>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleTicket;
