const loadRecaptcha = () => {
  const existingScript = document.getElementById('googleRecaptcha');
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${process.env.RECAPTCHA_SITE_KEY}`;
    script.id = 'googleRecaptcha';
    document.body.appendChild(script);
    // script.onload = () => { 
    //   if (callback) callback();
    // };
  }
  // if (existingScript && callback) callback();
};
export default loadRecaptcha;