import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const SingleSpeaker = ({ data }) => {
    const { title, acfExperts } = data
    const { company, expertIn, image } = acfExperts.details
    return (
      <div className="single-speaker grid grid-cols-speakers sm:flex sm:grid-cols-1 sm:flex-col sm:gap-x-12 max-w-[280px]	   ">
        {image && (
          <div>
            <GatsbyImage
              image={image.localFile.childImageSharp.gatsbyImageData}
              alt={image.altText}
              className="w-full h-full"
            />
          </div>
        )}
        <div className="flex justify-center flex-col pl-4 sm:pl-0 sm:pt-4">
          {title && (
            <p className="lg:text-[18px]">
              <strong className="mr-2 lg:text-[20px]">Name:</strong>
              {title}
            </p>
          )}
          {company && (
            <p className="lg:text-[18px]">
              <strong className="mr-2 lg:text-[20px]">Company:</strong>
              {company}
            </p>
          )}
          {expertIn && (
            <p className="lg:text-[18px]">
              <strong className="mr-2 lg:text-[20px]">Expert In:</strong>
              {expertIn}
            </p>
          )}
        </div>
      </div>
    );
  };

  export default SingleSpeaker;
