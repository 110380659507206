import React from "react";
import Tick from "components/icons/tick";
import BlackTick from "components/icons/black-tick";

import CMSLink from "components/shared/cms-link";
import SingleTicket from "components/shared/single-ticket";
import { graphql, useStaticQuery } from "gatsby";

const Tickets = ({ data }) => {
  const { tickets, title } = data;
  const { features } = useStaticQuery(graphql`
    query FeatureQuery {
      features: allWpTicketFeature {
        nodes {
          name
          id
          afcTicketFeature {
            highlight
          }
        }
      }
    }
  `);
  return (
    <section className="bg-dark-grey relative z-10 ">
      <div className="max-w-main w-90 mx-auto py-5 lg:py-10">
        {title && <h2 className="text-5xl mb-14 mt-4">{title}</h2>}
        <MobileTickets data={tickets} />
        <DesktopTickets data={tickets} features={features} />
      </div>
    </section>
  );
};

export default Tickets;

const MobileTickets = ({ data }) => {
  return (
    <div className="flex flex-col gap-12 sm:max-w-[400px] mx-auto lg:hidden">
      {data.map((ticket, i) => {
        return <SingleTicket data={ticket} key={i} order={i} />;
      })}
    </div>
  );
};
const DesktopTickets = ({ data, features }) => {
  //* get ticket with most features to display which should be the last ticket
  //! If they plan  to change order than layout will break
  const allTicketFeature = data[data.length - 1].acfTickets.ticketFeatures;
  const featureIds = [];
  const filtereatureIds = allTicketFeature.map((item, i) => {
    return featureIds.push(item.name);
  });
  return (
    <div className="hidden lg:block max-w-xl">
      <div className="desktopGridTickets  xl:pt-6">
        <div className="w-full h-full border-r-2 border-white"></div>
        {data.map((ticket, i) => {
          const {
            link,
            description,
            markdownPrice,
            packageValue,
            price,
            notOnSaleText,
            ticketOptions,
            title,
            ticketOptionTitle,
            titleLink,
            secondLink,
            ticketPackageLink,
          } = ticket.acfTickets;
          return (
            <div
              key={i}
              className="text-center relative h-[325px] xl:h-[390px]"
            >
              {ticketOptions === "yes" ? (
                <div
                  className={`${
                    i === 2 ? "bg-mint border-mint" : "bg-white border-white"
                  } ${
                    i === 0 && "border-l-2 border-white"
                  } absolute top-[-2.85rem] w-full  text-black text-center py-2 text-[20px] xl:p-5 xl:top-[-4.3rem]`}
                >
                  {ticketPackageLink ? (
                    <CMSLink
                      url={ticketPackageLink.url}
                      target={ticketPackageLink.target}
                      noStyle
                      type="normal"
                    >
                      <p className="text-black font-display font-bold underline">
                        {ticketOptionTitle?.toUpperCase()}
                      </p>
                    </CMSLink>
                  ) : (
                    <strong className="text-black">
                      <p className="font-display font-black">
                        {ticketOptionTitle?.toUpperCase()}
                      </p>
                    </strong>
                  )}
                </div>
              ) : (
                <div className=" absolute top-[-2.85rem] w-full border-white text-black text-center py-2 text-[20px] xl:p-5 opacity-1">
                  <p>
                    <strong className="font-display font-black ">&nbsp;</strong>
                  </p>
                </div>
              )}
              <div className="px-4 py-4 h-[400px] border-r-2 border-t-2 border-white">
                {title && (
                  <>
                    {titleLink ? (
                      <CMSLink
                        url={titleLink.url}
                        target={titleLink.target}
                        noStyle
                        type="normal"
                        className={`${i === 2 ? 'text-mint' : 'text-white'} underline`}
                      >
                        <h3 className="underline text-[18px] mb-0 pb-0 xl:text-[22px]">
                          <strong
                            className={`font-display ${
                              i === 2 ? "text-mint" : "font-black"
                            }`}
                          >
                            {title}
                          </strong>
                        </h3>
                      </CMSLink>
                    ) : (
                      <h3 className="text-[18px] mb-0 pb-0 xl:text-[22px]">
                        <strong
                          className={`font-display ${
                            i === 2 ? "text-mint" : "font-black"
                          }`}
                        >
                          {title}
                        </strong>
                      </h3>
                    )}
                  </>
                )}
                {description && (
                  <p className="text-[12px] mt-2 xl:text-[14px] xl:w-[90%] xl:mx-auto">
                    {description}
                  </p>
                )}
                <div className="absolute w-full text-center bottom-6 left-0 border-r-2 border-white xl:bottom-8">
                  {markdownPrice && (
                    <p className="line-through text-[#D8D8D8] text-[18px]">{`$${markdownPrice}`}</p>
                  )}
                  {packageValue ? (
                    <h5
                      className={`font-bold font-display text-[12px] xl:text-[18px] xl:my-3 ${
                        i === 2 && "text-mint"
                      } `}
                    >{`${packageValue}`}</h5>
                  ) : (
                    <h5 className="font-bold font-display text-[12px] xl:text-[18px] xl:my-3 opacity-0">
                      -
                    </h5>
                  )}
                  {price && (
                    <h4
                      className={`text-[30px] py-2 leading-[30px] xl:text-[50px] xl:my-2 ${
                        i === 2 && "text-mint"
                      }`}
                    >{`$${price}`}</h4>
                  )}
                  {notOnSaleText && (
                    <h4 className="text-[25px] py-2 leading-[30px] xl:text-[30px] xl:my-3">
                      {notOnSaleText}
                    </h4>
                  )}
                  {/* {link && (
                    <div className="ticketButtonOverride w-[80%] mx-auto mt-5 max-w-[200px] ">
                      <CMSLink url={link.url} target={link.target}>
                        {link.title}
                      </CMSLink>
                    </div>
                  )} */}
                  {link ? (
                    <div className="ticketButtonOverride w-[80%] mx-auto mt-5 max-w-[200px] ">
                      <CMSLink url={link.url} target={link.target}>
                        {link.title}
                      </CMSLink>
                    </div>
                  ) : (
                    <div className="ticketButtonOverride w-[80%] mx-auto mt-5 max-w-[200px] h-[44px]  ">
                      <p className="hidden">empty</p>
                    </div>
                  )}
                  {secondLink && (
                    <div className="absolute bottom-[-2rem] text-center w-full text-white underline [&>a]:text-white">
                      <CMSLink
                        url={secondLink.url}
                        target={secondLink.target}
                        noStyle
                        type="normal"
                        className=""
                      >
                        {secondLink.title}
                      </CMSLink>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="desktopGridTickets">
        <div className="w-[200px] singleTicketGrid xl:w-[250px] border-b-2">
          {/* {allTicketFeature.map((feature, i) => { */}
          {features.nodes.map((feature, i) => {
            return (
              <p
                key={i}
                className={`w-full border-b-2 border-r-2 text-[14px] py-2 pr-2 flex items-center xl:text-[15px] ${
                  feature.afcTicketFeature.highlight &&
                  "bg-gold text-black px-4"
                }`}
              >
                {feature.name}
              </p>
            );
          })}
        </div>
        {data.map((ticket, i) => {
          console.log(ticket);
          // we have a list of feature ids  featureIds
          // map over these ids and compare to the ticket feature id to display tick : empty
          const ticketFeatureIds = [];
          const filterTicketIds = ticket.acfTickets.ticketFeatures.map(
            (item, i) => {
              return ticketFeatureIds.push(item.name);
            }
          );
          return (
            <div className="parent" key={i}>
              {featureIds.map((item, i) => {
                return (
                  <div
                    className={`child ${
                      item === "Exclusive MINT Drop Giveaway" ? "bg-gold" : ""
                    }`}
                    key={i}
                  >
                    {/* <>
                    {item === 'Exclusive MINT Drop Giveaway' && (
                      <>
                      {ticketFeatureIds.includes(item) ? <GoldTick /> : <p></p>}  
                      </>
                    )}
                    {ticketFeatureIds.includes(item) ? <Tick /> : <p></p>}
                    </> */}
                    <>
                      {item === "Exclusive MINT Drop Giveaway" ? (
                        <>
                          {ticketFeatureIds.includes(item) ? (
                            <BlackTick />
                          ) : (
                            <p></p>
                          )}{" "}
                        </>
                      ) : (
                        <>
                          {ticketFeatureIds.includes(item) ? <Tick /> : <p></p>}{" "}
                        </>
                      )}
                    </>
                  </div>
                );
              })}
              {/* {ticket.acfTickets.link && (
                <div
                  className={`ticketButtonOverride w-[100%] text-center flex items-center justify-center py-10 border-r-2 border-b-2 ticketButtons button${i}`}
                >
                  <div className="w-[80%] mx-auto max-w-[200px]">
                    <CMSLink
                      url={ticket.acfTickets.link.url}
                      target={ticket.acfTickets.link.target}
                    >
                      {ticket.acfTickets.link.title}
                    </CMSLink>
                  </div>
                </div>
              )} */}
              {ticket.acfTickets.link ? (
                <>
                  <div
                    className={`relative ticketButtonOverride gridSize w-[100%] text-center flex items-center justify-center py-10 border-r-2 border-b-2 ticketButtons button ${
                      i === 0 ? "border-l-2" : ""
                    }`}
                  >
                    <div className="w-[80%] mx-auto max-w-[200px]">
                      <CMSLink
                        url={ticket.acfTickets.link.url}
                        target={ticket.acfTickets.link.target}
                      >
                        {ticket.acfTickets.link.title}
                      </CMSLink>
                      {ticket.acfTickets.secondLink && (
                        <div className="text-center w-full absolute left-0 bottom-[.4rem] text-white underline [&>a]:text-white">
                          <CMSLink
                            url={ticket.acfTickets.secondLink.url}
                            target={ticket.acfTickets.secondLink.target}
                            noStyle
                            type="normal"
                            className=""
                          >
                            {ticket.acfTickets.secondLink.title}
                          </CMSLink>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <div
                  className={`ticketButtonOverride w-[100%] text-center flex items-center justify-center py-10 border-r-2 border-b-2 ticketButtons button`}
                >
                  <></>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
