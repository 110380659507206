import React, { useEffect, useState } from "react"
import { validateRequired, validateEmail, validateFile } from "utils/validations"
import clsx from "clsx"
import loadRecaptcha from "utils/loadRecaptcha"
import Button from "components/shared/button"

const AuctionForm = ({data}) => {
  const [values, setValues] = useState({
    name: '',
    lastname: '',
    email: '',
    phone: '',
    description: '',
    file1: '',
    file2: '',
    file3: '',
    doc1: '',
    doc2: '',
  })
  const [required, setRequired] = useState({
    name: '',
    lastname: '',
    email: '',
    phone: '',
    description: '',
    file1: '',
  })
  const [validate, setValidate] = useState({
    name: '',
    lastname: '',
    email: '',
    phone: '',
    description: '',
    file1: '',
    file2: '',
    file3: '',
    doc1: '',
    doc2: '',
  })
  const [errors, setErrors] = useState({
    file1: "Please include 1 image.",
    file2: "",
    file3: "",
    doc1: "",
    doc2: ""
  })
  const [fileForLabel, setFileForLabel] = useState(1)
  const [docForLabel, setDocForLabel] = useState(1)
  const [success, setSuccess] = useState(false)
  const [sendingFormRequest, setSendingFormRequest] = useState(false)

  const {
    sendTo,
    text,
    successText
  } = data

  useEffect(() => {
    setFileLabel()
    setDocLabel()
    loadRecaptcha()
  }, [values])

  const handleChange = (e, name=false, value=false) => {
    if (e !== false) {
      const { target } = e
      name = target.name
      value = target.value

      if (e.target.type === 'file' && e.target.files[0]) {
        const file = e.target.files[0]
        const fileSize = file.size / 1024 / 1024; 
        if (fileSize <= 2) {
          value = {}
          value.value = file
          value.name = file.name
          value.type = "file"
          value.size = file.size
          setValidate({
            ...validate,
            [name]: ""
          })
          setErrors({
            ...errors,
            [name]: name === "file1" ? "Please include 1 image." : ""
          })
        } else {
          setValidate({
            ...validate,
            [name]: false
          })
          setErrors({
            ...errors,
            [name]: "File must not exceed 2mb."
          })
          return
        }
      }
    }

    // console.log(value)

    // set required fields value
    if (required.hasOwnProperty(name)) {
      setRequired(required => ({
        ...required,
        [name]: value,
      }))
    }

    // Update value in state
    setValues(values => ({
      ...values,
      [name]: value,
    }))
  }

  const setFileLabel = () => {
    const { file1, file2, file3 } = values
    if (file1 === '') {
      setFileForLabel(1) 
      return
    }

    if (file2 === '') {
      setFileForLabel(2) 
      return
    }

    if (file3 === '') {
      setFileForLabel(3) 
      return
    }

    // all files set
    setFileForLabel(4)
  }

  const setDocLabel = () => {
    const { doc1, doc2 } = values
    if (doc1 === '') {
      setDocForLabel(1) 
      return
    }

    if (doc2 === '') {
      setDocForLabel(2) 
      return
    }
    // all files set
    setDocForLabel(3)
  }

  const humanFileSize = (bytes, si=false, dp=1) => {
    const thresh = si ? 1000 : 1024;
  
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
  
    const units = si 
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;
  
    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  
    return bytes.toFixed(dp) + ' ' + units[u];
  }

  const resetValues = () => {
    let newVals = {}
    for (let key in values) {
      newVals[key] =  ""
    }
    setValues({
      ...values,
      ...newVals
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setSendingFormRequest(true)

    let errors = false
    const validateObj = {}
    for (const key in validate) {
      if (required[key] === '' && key) {
        validateObj[key] = false
        errors = true
      } else {
        validateObj[key] = true
      }
      setValidate(validateObj)
    }

    // return if errors
    if (errors) {
      setSendingFormRequest(false)
      return
    }

    window.grecaptcha.enterprise.ready(async function() {
      window.grecaptcha.enterprise.execute(process.env.RECAPTCHA_SITE_KEY, {action: 'auctionform'}).then(async function(token) {
        // create the data to send and add sendTo to data
        let formData = new FormData();
        for (let key in values) {
          if (key === "file1" || key === "file2" || key === "file3" || key === "doc1" || key === "doc2") {
            if (values[key].value) {
              // append the actual file
              formData.append(key, values[key].value)
            }
          } else {
            formData.append(key, values[key])
          }
        }
        formData.append("sendTo", sendTo)
        formData.append("grecaptcha_token", token)

        // console.log([...formData])

        let endpoint = `${process.env.WPGRAPHQL_URL.replace('/graphql', '')}/wp-json/api/v2/auction`
        const wpAuthorization = btoa(`${process.env.CMS_HTTP_USERNAME}:${process.env.CMS_HTTP_PASSWORD}`)

        const res = await fetch(endpoint, {
          method: 'POST',
          headers: {
            authorization: `Basic ${wpAuthorization}`
          },
          body: formData,
          redirect: 'follow'
        })
        .catch(error => {
          console.log(error)
          // return error.response
        });

        const json = res ? await res.json() : null
    
        setSendingFormRequest(false)
        if (json?.success) {
          // reset values to empty and success to true
          setSuccess(true)
          resetValues()
        } else {
          // loop errors and display error messages
          if (json?.errors) {
            let errors = {}
            for (let key in json.errors) {
              errors[key] ="has-danger"
            }
            setValidate({
              ...validate,
              ...errors
            })
          }
        }
      });
    });
  }

  return(
    <div className="bg-gradient-to-b from-dark-blue to-darker-blue py-20" id="vendor-form">
      <div className="container max-w-main w-90">
        {text && (
          <div
            className="[&>h3]:text-2xl [&>h3]:md:text-3xl md:mb-12 [&>h3]:xl:text-5xl [&>p]:xl:text-[20px] [&>p]:text-[20px] [&>h3]:xxl:text-[40px] pb-4"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        )}
        <form 
          onSubmit={e => {
            e.preventDefault()
            if (!sendingFormRequest) {
              handleSubmit(e)
            }
          }}
          noValidate
          className="pb-8 max-w-[950px]"
        > 
          <div className="md:flex md:flex-wrap md:-mx-4">
            <div className="md:basis-1/2 md:px-4 pb-4 md:pb-6">
              <label htmlFor="name" className="pb-2 block">First name</label>
              <input 
                type="text" 
                name="name"
                id="name"
                value={values.name} 
                placeholder="Enter your first name"
                onChange={e => {
                  handleChange(e)
                  validateRequired(validate, e)
                }}
                className="text-black p-3 w-full placeholder:text-light-grey"
              />
              {(!validate.name && validate.name !== '') &&
                <p className="text-pink">Your first name is required</p>
              }
            </div>
            <div className="md:basis-1/2 md:px-4 pb-4 md:pb-6">
              <label htmlFor="lastname" className="pb-2 block">Last name</label>
              <input 
                type="text" 
                name="lastname"
                id="lastname"
                value={values.lastname} 
                placeholder="Enter your last name"
                onChange={e => {
                  handleChange(e)
                  validateRequired(validate, e)
                }}
                className="text-black p-3 w-full placeholder:text-light-grey"
              />
              {(!validate.lastname && validate.lastname !== '') &&
                <p className="text-pink">Your last name is required</p>
              }
            </div>
            <div className="md:basis-1/2 md:px-4 pb-4 md:pb-6">
              <label htmlFor="email" className="pb-2 block">Email</label>
              <input 
                type="email" 
                name="email"
                id="email"
                value={values.email} 
                placeholder="Enter your email"
                onChange={e => {
                  handleChange(e)
                  validateRequired(validate, e)
                  validateEmail(validate, e)
                }}
                className="text-black p-3 w-full placeholder:text-light-grey"
              />
              {(!validate.email && validate.email !== '') &&
                <p className="text-pink">Your email is required</p>
              }
            </div>
            <div className="md:basis-1/2 md:px-4 pb-4 md:pb-6">
              <label htmlFor="phone" className="pb-2 block">Phone Number</label>
              <input 
                type="text" 
                name="phone"
                id="phone"
                value={values.phone} 
                placeholder="Enter your phone number (+1)"
                onChange={e => {
                  handleChange(e)
                  validateRequired(validate, e)
                }}
                className="text-black p-3 w-full placeholder:text-light-grey"
              />
              {(!validate.phone && validate.phone !== '') &&
                <p className="text-pink">Your phone number is required</p>
              }
            </div>
          </div>
          <div className="md:-mx-4">
            <div className="md:px-4 pb-4 md:pb-6">
              <label htmlFor="description" className="pb-2 block">Item/Collection description</label>
              <textarea 
                name="description"
                id="description"
                placeholder="Brief description of item or collection" 
                value={values.description} 
                rows="8"
                onChange={e => {
                  handleChange(e)
                  validateRequired(validate, e)
                }}
                className="text-black p-3 w-full placeholder:text-light-grey"
              />
              {(!validate.description && validate.description !== '') &&
                <p className="text-pink">This field is required</p>
              }
            </div>
          </div>
          <div className="md:-mx-4">
            <div className="md:px-4 pb-4 md:pb-6">
              <div className="pb-2 flex">
                <div className="pr-4">
                  {fileForLabel < 4 && (
                    <label htmlFor={`file${fileForLabel}`}>
                      <div className="relative h-[60px] w-[60px] gradient-2 rounded-full before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:h-[40%] before:w-[3px] before:bg-white before:rotate-90 after:absolute after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:h-[40%] after:w-[3px] after:bg-white hover:bg-hoverPink hoverPink hover:cursor-pointer">
                        <span className="sr-only">Add file</span>
                      </div>
                    </label>
                  )}
                </div>
                <div>
                  <h4 className="pb-0">Upload Image(s)</h4>
                  Max 3 images, 2mb max file size (each). File types permitted: jpg, jpeg & png
                </div>
              </div>

              {[...Array(3)].map((e, i) => {
                const n = i + 1
                return(
                  <div key={`file${n}`}>
                    <input 
                      type="file" 
                      name={`file${n}`} 
                      id={`file${n}`}  
                      accept=".jpg,.jpeg,.png" 
                      onChange={(e) => {
                        handleChange(e)
                        // validateFile(e, validate, setValidate, errors, setErrors)
                      }}
                      className="hidden"
                    />
                    {(!validate[`file${n}`] && validate[`file${n}`] !== '') &&
                      <p className="text-pink">{errors[`file${n}`]}</p>
                    }
                    {(values[`file${n}`] !== '') &&
                      <div className="pl-20 flex">
                        <button 
                          onClick={() => {
                            setValues({
                              ...values,
                              [`file${n}`]: ""
                            })
                            setFileLabel()
                            if (`file${n}` === "file1") {
                              setValidate({
                                ...validate,
                                file1: false
                              })
                            }
                          }}
                          className={`p-2 font-bold ${`file${fileForLabel-1}` !== `file${n}` ? "invisible" : ""}`}
                        >
                          <div className="relative h-[35px] w-[35px] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:h-[60%] before:w-[3px] before:bg-white before:rotate-45 after:absolute after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:h-[60%] after:w-[3px] after:bg-white after:-rotate-45"></div>
                        </button>
                        <div>
                          <p className="font-bold pb-0">{values[`file${n}`].name}</p>
                          <p>{humanFileSize(values[`file${n}`].size)}</p>
                        </div>
                      </div>
                    }
                  </div>
                )})
              }
            </div>
          </div>
          <div className="md:-mx-4">
            <div className="md:px-4 pb-4 md:pb-6">
              <div className="pb-2 flex">
                <div className="pr-4">
                  {docForLabel < 3 && (
                    <label htmlFor={`doc${docForLabel}`}>
                      <div className="relative h-[60px] w-[60px] gradient-2 rounded-full before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:h-[40%] before:w-[3px] before:bg-white before:rotate-90 after:absolute after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:h-[40%] after:w-[3px] after:bg-white hover:bg-hoverPink hoverPink hover:cursor-pointer">
                        <span className="sr-only">Add file</span>
                      </div>
                    </label>
                  )}
                </div>
                <div>
                  <h4 className="pb-0">Upload File(s)</h4>
                  Max 2 files, 2mb max file size (each). File types permitted: csv, xlsx & pdf
                </div>
              </div>

              {[...Array(2)].map((e, i) => {
                const n = i + 1
                return(
                  <div key={`doc${n}`}>
                    <input 
                      type="file" 
                      name={`doc${n}`} 
                      id={`doc${n}`}  
                      accept=".csv,.xlsx,.pdf" 
                      onChange={(e) => {
                        handleChange(e)
                        // validateFile(e, validate, setValidate, errors, setErrors)
                      }}
                      className="hidden"
                    />
                    {(validate[`doc${n}`] && validate[`doc${n}`] === 'has-danger') &&
                      <p className="text-pink">{errors[`doc${n}`]}</p>
                    }
                    {(values[`doc${n}`] !== '') &&
                      <div className="pl-20 flex">
                        <button 
                          onClick={() => {
                            setValues({
                              ...values,
                              [`doc${n}`]: ""
                            })
                            setDocLabel()
                            if (`doc${n}` === "doc1") {
                              setValidate({
                                ...validate,
                                doc1: false
                              })
                            }
                          }}
                          className={`p-2 font-bold ${`doc${docForLabel-1}` !== `doc${n}` ? "invisible" : ""}`}
                        >
                          <div className="relative h-[35px] w-[35px] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:h-[60%] before:w-[3px] before:bg-white before:rotate-45 after:absolute after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:h-[60%] after:w-[3px] after:bg-white after:-rotate-45"></div>
                        </button>
                        <div>
                          <p className="font-bold pb-0">{values[`doc${n}`].name}</p>
                          <p>{humanFileSize(values[`doc${n}`].size)}</p>
                        </div>
                      </div>
                    }
                  </div>
                )})
              }
            </div>
          </div>
          <Button className="mb-2 mt-0 relative" type="submit">
            Submit 
            <div role="status"
            className={clsx({
              "absolute right-1 top-1/2 -translate-y-1/2": true,
              "opacity-0": !sendingFormRequest
            })}>
              <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          </Button>
        </form>
        {(success && successText) && (
          <div dangerouslySetInnerHTML={{ __html: successText }} className="text-white text-2xl" id="vendor-success" />
        )}
      </div>
    </div>
  )
}

export default AuctionForm