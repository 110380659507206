import React, { useLayoutEffect, useRef, useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const FullWidthVideo = ({ data }) => {
  const { video, mobileCoverImage, desktopCoverImage } = data;
  const [playing, setPlaying] = useState(false);
  const videoRef = useRef();
  const clickToPlay = () => {
    videoRef.current.play()
    if (playing) {
      videoRef.current.pause()
      setPlaying(false)
    } else {
      videoRef.current.play()
      setPlaying(true)
    }
  }
  // useLayoutEffect(() => {
  //   setTimeout(() => {
  //     if (videoRef.current.paused) {
  //       // videoRef.current.play();
  //       // setPlaying(true);
  //     }
  //   }, 2500);
  // }, []);
  // console.log(desktopCoverImage);
  return (
    <div
      className={`bg-dark-grey relative z-10 max-w-xxl mx-auto`}
      onClick={() => clickToPlay()}
    >
      <div className="[&>video]:w-full [&>video]:h-full relative ">
        <video
          // playsInline
          // muted
          // loop
          playing={playing}
          play
          controls
          autoPlay={false}
          ref={videoRef}
          style={{ opacity: playing ? "1" : "0", transition: "all .5s ease" }}
        >
          <source src={video} type="video/mp4" />
        </video>
        {mobileCoverImage && (
          <div className={`${playing ? 'opacity-0' : 'opacity-1'} w-full h-full absolute top-0 left-0 right-0 bottom-0 md:hidden`}>
          <GatsbyImage
            className=""
            image={mobileCoverImage.localFile.childImageSharp.gatsbyImageData}
            alt={mobileCoverImage.altText}
          />
        </div>
        )}
        {desktopCoverImage && (
          <div className={`${playing ? 'opacity-0' : 'opacity-1'} hidden md:block md:w-full md:h-full md:absolute md:top-0 md:left-0 md:right-0 md:bottom-0`}>
          <GatsbyImage
            image={desktopCoverImage.localFile.childImageSharp.gatsbyImageData}
            alt={desktopCoverImage.altText}
          />
        </div>
        )}
      </div>
    </div>
  );
};

export default FullWidthVideo;
