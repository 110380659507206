import React, { useEffect, useRef } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import ReactPlayer from "react-player";

import CMSLink from "components/shared/cms-link";
import { filterCmsLinks } from "utils/helpers";

import { gsap } from 'utils/gsap'

const VideoWithText = ({ data }) => {
  const { background, video, backgroundImage, textPosition, text, link } = data;
  const videoWithTextRef = useRef();
  const tl = useRef();
  useEffect(() => {
    const ctx = gsap.context(() => {
      tl.current = gsap.timeline({
        duration: .4,
        scrollTrigger: {
          trigger: videoWithTextRef.current,
        }
      })
        .from('.text', {
          opacity: 0,
          x: '-100px',
          stagger: 0.25
        })
        .from('.video', {
          opacity: 0,
          x: '-100px',
          stagger: 0.25
        }, "<");
    }, videoWithTextRef);
  }, []);
  return (
    <div
      className={`relative ${background === "black" && "bg-dark-grey"} ${
        background === "green" && "bg-teal"
      } ${background === "gradient" && "gradient-1"}`}
    >
      <div ref={videoWithTextRef} className="relative z-20 max-w-main w-90 mx-auto py-16 md:flex md:gap-10 md:items-center md:flex-row md:justify-center xl:py-24">
        <div
          className={`text md:w-1/2 md:h-full ${
            textPosition === "left" ? "order-1" : "order-2"
          }`}
        >
          {text && (
            <div
              className="[&>h3]:text-2xl [&>p]:pb-6 [&>h3]:md:text-3xl [&>h3]:xl:text-5xl [&>p]:xl:text-[20px] [&>h3]:xxl:text-[40px]"
              dangerouslySetInnerHTML={{ __html: filterCmsLinks(text) }}
            />
          )}
          {link && (
            <CMSLink url={link.url} target={link.target} className="mt-2 block">
              {link.title}
            </CMSLink>
          )}
        </div>
        {video && (
          <div
            className={`video mt-12 md:w-1/2 md:mt-0 aspect-video	 ${
              textPosition === "left" ? "order-2" : "order-1"
            }`}
          >
            <ReactPlayer
              url={video}
              config={{
                youtube: {
                  playerVars: {
                    // https://developers.google.com/youtube/player_parameters
                    autoplay: 0,
                    controls: 0,
                    autohide: 1,
                    rel: 0,
                    modestbranding: 1,
                    loop: 1,
                    mute: 1,
                    fs: 0,
                    playsinline: 1,
                  },
                  // onUnstarted: onVideoError
                },
              }}
              controls={false}
              className=""
              width="100%"
              height="100%"
              muted={true}
              loop={true}
              playsinline={true}
            />
          </div>
        )}
      </div>
      {backgroundImage && (
        <div className="inset-0 h-full w-full object-cover absolute pointer-events-none  after:content:[''] after:absolute after:inset-0 after:w-full after:h-full after:bg-dark-overlay after:z-0">
          <GatsbyImage
            image={backgroundImage.localFile.childImageSharp.gatsbyImageData}
            alt={backgroundImage.altText}
            className="h-full w-full"
          />
        </div>
      )}
    </div>
  );
};

export default VideoWithText;
