import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"

const Dropdown = ({
  name, 
  label, 
  placeholder, 
  dropdownValues, 
  validate, 
  handleChange, 
  validateRequired, 
  values
}) => {
  const [open, setOpen] = useState(false)
  return(
    <div className="relative">
      <label for="name" className="pb-2 block">{label}</label>
      <button 
        className={`${values[name] === "" ? 'text-light-grey' : 'text-black' } p-3 w-full text-left bg-white flex justify-between items-center`}
        id={name}
        onClick={(e) => {
          e.preventDefault()
          setOpen(!open)}
        }
      >
        {values[name] === "" ? placeholder : values[name]}
        <FontAwesomeIcon icon={faAngleDown} className="text-xl max-w-[30px] max-h-[30px]" />
      </button>
      <ul 
        aria-labelledby={name}
        className={`absolute top-full left-0 w-full z-50 drop-shadow-xl ${open ? '' : 'hidden'}`}
      >
        {dropdownValues?.map(({value}, i) => (
          <li 
            key={`${name}${i}`} 
            className=""
          >
            <a 
              href="#"
              onClick={e => {
                e.preventDefault()
                handleChange(false, name, value)
                setOpen(false)
                validateRequired(validate, false, name, value)
              }}
              className={`block p-3 hover:bg-pink hover:text-white ${value === values[name] ? 'bg-pink text-white' : 'bg-white text-black'}`}
            >
              {value}
            </a>
          </li>
        ))}
      </ul>
      {(!validate[name] && validate[name] !== '') &&
        <p className="text-pink">This field is required</p>
      }
    </div>
  )
}

export default Dropdown