import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import { filterCmsLinks } from "utils/helpers";
import CMSLink from "../../shared/cms-link";
const urlencode = require("urlencode");

const TwoColumnVenues = ({ data }) => {
  const { venuetitle, venuecard } = data;
  return (
    <div className="max-w-main w-90 mx-auto py-8 md:relative md:py-14 xl:py-24">
      {venuetitle && (
        <h3 className="text-[25px] pb-0 md:text-3xl xl:text-5xl xxl:text-[40px]">
          {venuetitle}
        </h3>
      )}
      <div className="md:flex md:justify-between md:gap-5 lg:gap-10">
        {venuecard &&
          venuecard.map((venue, i) => <VenueCard data={venue} key={i} />)}
      </div>
    </div>
  );
};

export default TwoColumnVenues;

const VenueCard = ({ data }) => {
  const { venuecardaddress, venuecardimage, venuecardtext } = data;
  return (
    <div className="mt-8 mb-20 md:max-w-[593px] md:relative md:h-[985px] llg:h-[1050px] xl:h-[1200px]">
      {venuecardimage && (
        <GatsbyImage
          image={venuecardimage.localFile.childImageSharp.gatsbyImageData}
          alt={venuecardimage.altText}
          className="md:w-full md-h-full"
        />
      )}
      {venuecardtext && (
        <div
          className="mt-4 [&>h3]:text-[16px] [&>h3]:mt-7 [&>h4]:text-[14px] [&>h4]:mt-3 [&>p]:mt-3 [&>h3]:md:text-[20px] [&>h4]:md:text-[16px] [&>h3]:xl:text-[30px] [&>h4]:xl:text-[18px]"
          dangerouslySetInnerHTML={{ __html: filterCmsLinks(venuecardtext) }}
        />
      )}
      {venuecardaddress && (
        <div className="md:absolute md:bottom-0 md:w-full">
          <iframe
            className="mt-10 h-[250px] mb-8 md:h-[300px] xl:h-[440px]"
            width="100%"
            id="gmap_canvas"
            src={`https://maps.google.com/maps?q=${urlencode(
              venuecardaddress
            )}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
            allowFullScreen
          />
          <div className="w-[90%] text-center mx-auto">
            <CMSLink
              url={`https://maps.google.com/maps?q=${urlencode(
                venuecardaddress
              )}`}
              target="_blank"
              className="min-w-[250px] w-[250px] mx-auto md:w-[300px] md:min-w-[300px]"
            >
              VIEW ON GOOGLE MAPS
            </CMSLink>
          </div>
        </div>
      )}
    </div>
  );
};
