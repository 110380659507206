import React, { useEffect, useRef } from "react"
import CMSLink from "components/shared/cms-link"
import { filterCmsLinks } from "utils/helpers"
import { GatsbyImage } from "gatsby-plugin-image"
import clsx from "clsx"
import { gsap } from 'utils/gsap'

const TextFullWidth = ({data}) => {
  const {
    background,
    backgroundImage,
    text,
    paragraphPadding,
    link
  } = data
  const textFullWidthRef = useRef();
  const tl = useRef();
  useEffect(() => {
    const ctx = gsap.context(() => {
      tl.current = gsap.timeline({
        duration: .4,
        scrollTrigger: {
          trigger: textFullWidthRef.current,
          toggleActions: "play none none none",
          start: "top 75%"
          // start: "top top"
        }
      })
        .from(textFullWidthRef.current, {
          opacity: 0,
          x: '-100px',
          stagger: 0.25
        });
    }, textFullWidthRef);
  }, []);
  return(
    <div className={clsx({
      "relative": true,
      "bg-dark-grey": background === "black",
      "bg-teal": background === "green",
      "gradient-1": background === "gradient",
      "bg-white text-black": background === "white"
    })}>
      <div className={`max-w-main w-90 mx-auto   ${paragraphPadding === 'no' ? 'py-8 xl:py-16' : 'py-16 xl:py-24'}`}>
        <div ref={textFullWidthRef} className="relative z-[20]">
          {text &&
            <div className={`[&>ul]:list-disc [&>ul]:pl-5 [&>ul]:pb-3 [&>ul>li]:py-1 [&>h3]:text-2xl [&>h3]:mb-0 [&>h3]:md:text-3xl [&>p]:xl:text-[20px] [&>ul>li]:xl:text-[20px] [&>h3]:xl:text-[40px] ${paragraphPadding === 'yes' ? "[&>p]:mb-6" : '[&>p]:mb-0' }`} 
            dangerouslySetInnerHTML={{ __html: filterCmsLinks(text) }} 
          />
          }
          {link &&
            <CMSLink url={link.url} target={link.target} className="mt-6 block">
              {link.title}
            </CMSLink>
          }
        </div>
      </div>
      {backgroundImage && (
        <div className="inset-0 h-full w-full object-cover absolute pointer-events-none z-0 after:content:[''] after:absolute after:inset-0 after:w-full after:h-full after:bg-dark-overlay after:z-30">
          <GatsbyImage
            image={backgroundImage.localFile.childImageSharp.gatsbyImageData}
            alt={backgroundImage.altText}
            className="h-full w-full"
          />
        </div>
      )}
    </div>
  )
}

export default TextFullWidth