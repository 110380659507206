import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import CMSLink from "./cms-link";

const SingleVendor = ({ data }) => {
  const { localImage, acfVendors } = data;
  const { name, link, image } = acfVendors.details;
  return (
    <div className="vendor sm:basis-1/2 md:basis-1/3 lg:basis-1/4 px-4 pb-8">
      {link ? (
        <CMSLink
          url={link.url}
          target={link.target}
          type="normal"
          className="text-mint underline"
        >
          {image && (
            <GatsbyImage
              image={localImage.childImageSharp.gatsbyImageData}
              alt={image.altText}
            />
          )}
        </CMSLink>
      ) : (
        <>
        {image && (
          <GatsbyImage
            image={localImage.childImageSharp.gatsbyImageData}
            alt={image.altText}
          />
        )}</>
      )}
      {name && <div className="font-bold text-xl py-2">{name}</div>}
      {link && (
        <div>
          <CMSLink
            url={link.url}
            target={link.target}
            type="normal"
            className="text-mint underline"
          >
            {link.title}
          </CMSLink>
        </div>
      )}
    </div>
  );
};

export default SingleVendor;
