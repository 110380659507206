import React, { useEffect, useRef } from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import { filterCmsLinks } from "utils/helpers";
import CMSLink from "components/shared/cms-link";

import { gsap } from 'utils/gsap'


const TwoColumnText = ({ data }) => {
  const { leftBlock, rightBlock } = data;
  const blocks = [];
  blocks.push(leftBlock, rightBlock);
  const twoColumnTextRef = useRef();
  const tl = useRef();
  useEffect(() => {
    const ctx = gsap.context(() => {
      tl.current = gsap.timeline({
        duration: .4,
        scrollTrigger: {
          trigger: twoColumnTextRef.current,
        }
      })
        .from(twoColumnTextRef.current, {
          opacity: 0,
          x: '-100px',
          stagger: 0.25
        });
    }, twoColumnTextRef);
  }, []);
  return (
    <div className="md:grid md:grid-cols-2">
      {blocks.map((block, i) => {
        const { background, desktopImage, mobileImage, link, text, title } =
          block;
        return (
          <div
            key={i}
            className={`relative z-10 w-full h-full  ${
              background === "black" && "bg-dark-grey"
            } ${background === "green" && "bg-teal"} ${
              background === "gradient" && "gradient-1"
            }`}
          >
            <div
              ref={twoColumnTextRef}
              className={`w-90 mx-auto py-16 relative z-[20] max-w-[683px]  ${
                i === 0
                  ? "xl:mr-0 xl:ml-auto xl:pr-10"
                  : "xl:ml-0 xl:mr-auto xl:pl-12"
              }`}
            >
              {title && (
                <div
                  className="text-[25px] md:text-3xl xl:text-[40px] xl:leading-[1.15] md:mb-7"
                  dangerouslySetInnerHTML={{ __html: filterCmsLinks(title) }}
                />
              )}
              {text && (
                <div
                  className="[&>p]:mb-4 [&>ul]:list-disc [&>ul]:pl-5"
                  dangerouslySetInnerHTML={{ __html: filterCmsLinks(text) }}
                />
              )}
              {link && (
                <div className="mt-8 block w-[80%] mx-auto md:mr-auto md:ml-0 md:mt-16">
                  <CMSLink url={link.url} target={link.target}>
                    {link.title}
                  </CMSLink>
                </div>
              )}
            </div>
            {desktopImage && (
              <GatsbyImage
                image={desktopImage.localFile.childImageSharp.gatsbyImageData}
                alt={desktopImage.altText}
                className="hidden md:block h-full w-full object-cover absolute inset-0 top-0 left-0 bottom-0 right-0 pointer-events-none z-10 after:content after:absolute after:inset-0 after:w-full after:h-full after:bg-dark-overlay after:z-30"
              />
            )}
            {mobileImage && (
              <GatsbyImage
                image={mobileImage.localFile.childImageSharp.gatsbyImageData}
                alt={desktopImage.altText}
                className="md:hidden h-full w-full object-cover absolute inset-0 top-0 left-0 bottom-0 right-0 pointer-events-none z-10 after:content after:absolute after:inset-0  after:w-full after:h-full after:bg-dark-overlay after:z-30"
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default TwoColumnText;