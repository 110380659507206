import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import { filterCmsLinks } from "utils/helpers";
import CMSLink from "../../shared/cms-link";

const urlencode = require("urlencode");

const SingleVenue = ({ data }) => {
  const { text, title, image, googleMapsAddress } = data;
  return (
    <div className="relative z-10 bg-dark-grey max-w-main w-90 mx-auto py-8 md:relative md:py-16 ">
      <div>
        {title && (
          <h3 className="text-[25px] pb-0 md:text-3xl xl:text-5xl xxl:text-[40px]">
            {title}
          </h3>
        )}
        <div className="w-full md:grid md:grid-cols-2 md:my-6 md:gap-5 xl:gap-12 xl:mt-14">
          <div className="w-full my-4 md:my-0 xl:max-w-[600px]">
            {image && (
              <GatsbyImage
                image={image.localFile.childImageSharp.gatsbyImageData}
                alt={image.altText}
                className="md:w-full md-h-full"
              />
            )}
            {text && (
              <div
                className="mt-4 [&>h4]:text-[16px] [&>h5]:text-[16px] [&>p]:text-[14px] [&>p>strong]:block [&>p>strong]:text-bold [&>p>strong]:mb-3 [&>h4]:md:text-[25px] [&>p]:md:text-[16px] [&>h4]:xl:text-[30px] [&>p]:xl:text-[18px]"
                dangerouslySetInnerHTML={{ __html: filterCmsLinks(text) }}
              />
            )}
          </div>
          <div className="w-full h-full">
            {googleMapsAddress && (
              <div className="md:w-full">
                <iframe
                  className="mt-10 h-[330px] mb-8 md:mt-0 md:w-full xl:max-w-full xl:w-full xl:h-[545px]"
                  width="100%"
                  id="gmap_canvas"
                  src={`https://maps.google.com/maps?q=${urlencode(
                    googleMapsAddress
                  )}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                  allowFullScreen
                />
                <div className="w-[90%] text-center mx-auto">
                  <CMSLink
                    url={`https://maps.google.com/maps?q=${urlencode(
                      googleMapsAddress
                    )}`}
                    target="_blank"
                    className="min-w-[250px] w-[250px] mx-auto md:w-[300px] md:min-w-[300px]"
                  >
                    VIEW ON GOOGLE MAPS
                  </CMSLink>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleVenue;
