import { GatsbyImage } from "gatsby-plugin-image";
import React, { useEffect, useRef } from "react";
import CMSLink from "components/shared/cms-link";
import { filterCmsLinks } from "utils/helpers"

import { gsap } from 'utils/gsap'

const TextWithWhiteBox = ({ data }) => {
  const { 
    background,
    image, 
    textPosition, 
    text, 
    link 
  } = data;

  const textWithWhiteBoxRef = useRef();
  const tl = useRef();
  let xPosition
  if (textPosition === 'left') {
    xPosition = '-50px'
  } else {
    xPosition = '50px'
  }
  useEffect(() => {
    const ctx = gsap.context(() => {
      tl.current = gsap.timeline({
        duration: .4,
        scrollTrigger: {
          trigger: textWithWhiteBoxRef.current,
          toggleActions: "play none none none" ,
          start: "top center"
        }
      })
        .from('aside', {
          opacity: 0,
          x: xPosition
        });
    }, textWithWhiteBoxRef);
  }, []);

  return (
    <section ref={textWithWhiteBoxRef} className={`relative z-10 ${background === "black" && "bg-dark-grey"} ${background === "green" && "bg-teal"} ${background === "gradient" && "gradient-1"}`}>
      <div className="max-w-main w-90 mx-auto py-8 md:relative md:py-14 xl:py-24">
        {image && (
          <div className={`md:w-[80%] ${textPosition === "left" && "md:ml-auto"}`}>
            <GatsbyImage
              image={image.localFile.childImageSharp.gatsbyImageData}
              alt={image.altText}
            />
          </div>
        )}
        <aside className={`md:absolute md:top-0 md:h-full ${textPosition === "left" ? "md:left-0" : "md:right-0"} md:w-1/2 md:flex md:items-center xl:max-w-[550px]`}>
          <div className="bg-white text-black w-[96%] mx-auto py-10 px-6 rounded-main relative -top-12 md:top-0 xl:py-12 xl:px-14 ">
            {text && (
              <div
                className="[&>h3]:text-2xl [&>h3]:pb-0 [&>p]:pt-4 [&>p]:pb-4 [&>p]:w-90 [&>h3]:md:text-3xl  [&>p]:xl:text-[20px] [&>h3]:xl:text-[40px]"
                dangerouslySetInnerHTML={{ __html: filterCmsLinks(text) }}
              />
            )}
            {link && (
              <CMSLink url={link.url} target={link.target} className="mt-4">
                {link.title}
              </CMSLink>
            )}
          </div>
        </aside>
      </div>
    </section>
  );
};

export default TextWithWhiteBox;
